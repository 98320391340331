import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const stopApiRoutes = {
    defaultRoute: "stop/"
};

export class StopAPI {
    static getNearStops(latitude, longitude, radius) {
        const url = `${stopApiRoutes.defaultRoute}${latitude}/${longitude}/${radius}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getStopTimes(provider, stopId) {
        const url = `${stopApiRoutes.defaultRoute}${provider}/${stopId}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getPhysicalStopTimes(physicalStop) {
        const url = `${stopApiRoutes.defaultRoute}physicalStopTimes`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.POST,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey(),
            body: JSON.stringify(physicalStop)
        });
    }
}