import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { MapContainer, TileLayer, Tooltip, Pane, Polyline, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { UserLocationAsOriginButton } from '../UserLocationAsOriginButton'
import { DraggableMarker } from '../DraggableMarker';
import { SearchBar } from '../SearchBar';
import { PlanRouteCommand } from '../commands/PlanRouteCommand';
import { Button, Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { SwitchDirectionButton } from '../SwitchDirectionButton';
import { ProviderCommand } from '../commands/ProviderCommand';
import { StopCommand } from '../commands/StopCommand';
import { routeDestinationIcon, routeOriginIcon, stopIcon } from '../leaflet/StopIcons';
import DatePicker from "../libs/react-modern-calendar-datepicker/src/DatePicker";
import "../libs/react-modern-calendar-datepicker/src/DatePicker.css";
import TimeKeeper from 'react-timekeeper';
import { FilterButton } from './filter/FilterButton';


export class AllRoutesView extends Component {
    static contextTypes = {
        setState: PropTypes.func,
        getState: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        getLogo: PropTypes.func,
        getUserLocation: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "allRoutesView";
        this.originSearchBarRef = createRef();
        this.destinationSearchBarRef = createRef();
        this.mapRef = React.createRef();

        this.state = {
            commands: {
                stops: new StopCommand(),
                planRoute: new PlanRouteCommand(),
                provider: new ProviderCommand()
            },
            useUserLocation: false,
            userLocationPlace: {
                id: 0,
                code: "",
                coordX: 0,
                coordY: 0,
                name: strings.userLocationPlace,
                provider: "",
                regions: [],
                restriction: 0,
                type: 0
            },
            request: {
                startTime: undefined,
                endTime: undefined,
                type: 0, //RequestType 0 == RouteFinder
                route: [{ in: undefined, out: undefined }],
                preferences: undefined, //Preferences 0 == OptimiseArrivalTime, 1 == OptimiseChanges, 4 == OptimiseWalkTime
                changePreferences: 0, //Preferences 0 == OnFoot, 1 == OnBike, 2 == OnScooter
                maxResultTrips: undefined,
                isArrivalTime: true,
                providers: [],
                walkSpeed: 1,
                maximumRadius: 1000,
                username: "Website",
                ip: "",
                ticket: -1
            },
            requestPreferences: {
                0: "OptimiseArrivalTime",
                1: "OptimiseChanges",
                4: "OptimiseWalkTime",
                5: "OptimiseTourism"
            },
            requestChangePreferences: {
                0: "onFoot",
                1: "onBike",
                2: "onScooter"
            },
            availableTransportTypes: {
                1: "bus",
                2: "train",
                3: "boat",
                4: "plane"
            },
            requestTransportTypes: [1, 2, 3, 4], //1 == AUTOCARROS, 2 == COMBOIOS, 3 == FLUVIAL, 4 ==  AVIÃO
            providers: [],
            trips: [],
            polylines: [],
            timeFilterSelection: undefined,
            displayResults: false,
            resultChangePreferences: 0,
            planningRoutes: false,
            selectedDepartureDate: null,
            selectedDepartureTime: null,
            selectedArrivalDate: null,
            selectedArrivalTime: null,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getProviders();
            this.updateUserLocation();
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        setState(this.stateKey, this.state);
    }

    //DATA
    updateUserLocation() {
        const { getUserLocation } = this.context;
        let userLocation = getUserLocation();

        if (0 === userLocation.length) {
            return;
        }

        const { userLocationPlace, request } = this.state;
        userLocationPlace.coordX = userLocation[0];
        userLocationPlace.coordY = userLocation[1];
        request.route[0].in = userLocationPlace;
        this.originSearchBarRef.current.updateSearchTextWithoutSearching(userLocationPlace.name);

        this.setState({
            useUserLocation: true,
            userLocationPlace: userLocationPlace,
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () =>
            this.renderRoutePath()
        );
    }

    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        const { request } = this.state;

        request.providers = result.map((provider) => provider.name);
        request.preferences = 0;
        request.maxResultTrips = 3;

        this.setState({
            providers: result,
            request: request
        });
    }

    planRoute(e) {
        e.preventDefault();
        const { commands, request, providers, requestTransportTypes } = this.state;

        if (undefined === request.route[0].in || undefined === request.route[0].out) {
            return
        }

        request.providers = providers.filter(p => requestTransportTypes.findIndex(tt => tt === p.transportTypeId) !== -1).map(validProvider => validProvider.name);

        this.setState({
            planningRoutes: true,
            polylines: [],
            request: request
        }, () => {
            commands.planRoute.calculateRoute(request, (r) => this.planRouteSuccessCallback(r));
        });
    }

    planRouteSuccessCallback(result) {
        const resultTrips = undefined !== result.trips ? result.trips : [];
        this.setState({
            planningRoutes: false,
            displayResults: true,
            resultChangePreferences: result.changePreferences,
            trips: resultTrips,
        }, () => this.buildTripsPolylines(resultTrips));
    }

    //FUNCTIONS
    getBounds() {
        const { trips, polylines, displayResults } = this.state;

        if (!displayResults || 0 === trips.length || 0 === polylines) {
            return [{ lat: 36.9939155, lng: -8.9471321 }, { lat: 37.4699785, lng: -7.4730776 }];
        }

        const bounds = trips.reduce((previousValue, currentValue) => {
            if (previousValue.minLatitude > currentValue.minLatitude) {
                previousValue.minLatitude = currentValue.minLatitude;
            }

            if (previousValue.maxLatitude < currentValue.maxLatitude) {
                previousValue.maxLatitude = currentValue.maxLatitude;
            }

            if (previousValue.minLongitude > currentValue.minLongitude) {
                previousValue.minLongitude = currentValue.minLongitude;
            }

            if (previousValue.maxLongitude < currentValue.maxLongitude) {
                previousValue.maxLongitude = currentValue.maxLongitude;
            }

            return previousValue;
        }, {
            minLatitude: 90.0, //Max possilbe latitude value
            maxLatitude: -90.0, //Min possible latitude value
            minLongitude: 180.0, //Max possible longitude value
            maxLongitude: -180.0 //Min possilbe longitude value
        });

        return [{ lat: bounds.minLatitude, lng: bounds.minLongitude - 0.01 }, { lat: bounds.maxLatitude + 0.005, lng: bounds.maxLongitude }];
    }

    handleOptionsClick() {
        const { displayOptions } = this.state;

        this.setState({
            displayOptions: !displayOptions
        });
    }

    handlePreferencesChange = (e) => {
        const { request } = this.state;

        request.preferences = parseInt(e.currentTarget.value);

        this.setState({
            request: request
        });
    }

    handleChangePreferencesChange = (e) => {
        const { request, requestPreferences } = this.state;

        request.changePreferences = parseInt(e.currentTarget.value);
        switch (request.changePreferences) {
            case 2: // Scooter
                request.walkSpeed = 3;
                requestPreferences[4] = "OptimiseScooterTime";
                break;
            case 1: // Bicycle
                request.walkSpeed = 3;
                requestPreferences[4] = "OptimiseBicycleTime";
                break;
            default: // All others
                request.walkSpeed = 1;
                requestPreferences[4] = "OptimiseWalkTime";
                break;
        }

        this.setState({
            request: request,
            requestPreferences: requestPreferences
        });
    }

    handleTransportTypesChange = (e) => {
        const { requestTransportTypes } = this.state;
        const selectedTransportType = parseInt(e.currentTarget.value);
        const index = requestTransportTypes.findIndex(transportType => transportType === selectedTransportType);

        if (index === -1) {
            requestTransportTypes.push(selectedTransportType);
        } else {
            requestTransportTypes.splice(index, 1);
        }

        this.setState({
            requestTransportTypes: requestTransportTypes
        });
    }

    handleSelectStartDateOptionClick() {
        this.setState({
            displayArrivalDateDatePicker: false,
            displayDepartureDateDatePicker: true
        }, () => {
            //Simulate click to open calendar right away
            document.getElementsByName("plan-route-departure-date-picker-input")[0].click();
        });
    }

    handleSelectEndDateOptionClick() {
        this.setState({
            displayDepartureDateDatePicker: false,
            displayArrivalDateDatePicker: true
        }, () => {
            //Simulate click to open calendar right away
            document.getElementsByName("plan-route-arrival-date-picker-input")[0].click();
        });
    }

    handleOriginDrag(mapPoint) {
        const { request } = this.state;

        if (request.route[0].in.type === 0) {
            request.route[0].in.coordX = mapPoint.lat;
            request.route[0].in.coordY = mapPoint.lng;
            request.route[0].in.name = `${mapPoint.lat.toFixed(5)}, ${mapPoint.lng.toFixed(5)}`;
        } else {
            //Create a stop-like object
            let stop = { id: 0, code: "", coordX: mapPoint.lat, coordY: mapPoint.lng, name: `${mapPoint.lat.toFixed(5)}, ${mapPoint.lng.toFixed(5)}`, provider: "", regions: [], restriction: 0, type: 0 };
            request.route[0].in = stop;
        }

        this.originSearchBarRef.current.updateSearchTextWithoutSearching(request.route[0].in.name);

        this.setState({
            request: request
        }, () => {
            this.renderRoutePath();
        });
    }

    handleDestinationDrag(mapPoint) {
        const { request } = this.state;

        if (request.route[0].out.type === 0) {
            request.route[0].out.coordX = mapPoint.lat;
            request.route[0].out.coordY = mapPoint.lng;
            request.route[0].out.name = `${mapPoint.lat.toFixed(5)}, ${mapPoint.lng.toFixed(5)}`;
        } else {
            //Create a stop-like object
            let stop = { id: 0, code: "", coordX: mapPoint.lat, coordY: mapPoint.lng, name: `${mapPoint.lat.toFixed(5)}, ${mapPoint.lng.toFixed(5)}`, provider: "", regions: [], restriction: 0, type: 0 };
            request.route[0].out = stop;
        }

        this.destinationSearchBarRef.current.updateSearchTextWithoutSearching(request.route[0].out.name);

        this.setState({
            request: request
        }, () => {
            this.renderRoutePath();
        });
    }

    handleMapPointSelection(mapPoint) {
        const { request } = this.state;

        // Create a stop-like object
        let stop = {
            id: 0,
            code: "",
            coordX: mapPoint.lat,
            coordY: mapPoint.lng,
            name: `${mapPoint.lat.toFixed(5)}, ${mapPoint.lng.toFixed(5)}`,
            provider: "",
            regions: [],
            restriction: 0,
            type: 0
        };

        // If both are unset, we make the selected stop the origin
        if (undefined === request.route[0].in) {
            request.route[0].in = stop;
            this.originSearchBarRef.current.updateSearchTextWithoutSearching(stop.name);
        }
        else {
            request.route[0].out = stop;
            this.destinationSearchBarRef.current.updateSearchTextWithoutSearching(stop.name);
        }

        this.setState({
            request: request
        }, () => {
            this.renderRoutePath();
        });
    }

    handleStartStopSelection(stop) {
        const { request } = this.state;
        request.route[0].in = stop;

        this.mapRef.current.target.fire("click", { forcedEvent: true, latlng: { lat: stop.coordX, lng: stop.coordY } });

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () => {
            this.renderRoutePath();
        });
    }

    handleStartSearchBarClear() {
        const { request } = this.state;
        request.route[0].in = undefined;

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        });
    }

    handleEndStopSelection(stop) {
        const { request } = this.state;
        request.route[0].out = stop;

        this.mapRef.current.target.fire("click", { forcedEvent: true, latlng: { lat: stop.coordX, lng: stop.coordY } });

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () => {
            this.renderRoutePath();
        });
    }

    handleEndSearchBarClear() {
        const { request } = this.state;
        request.route[0].out = undefined;

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        });
    }

    handleTripSelected(trip) {
        const { onSelectRoute } = this.props;
        const { providers, resultChangePreferences } = this.state;

        onSelectRoute({ trip: trip, changePreferences: resultChangePreferences, providers: providers });
    }

    determineStopIcon(stop) {
        const { request } = this.state;

        if (undefined !== request.route[0].in && stop.code === request.route[0].in.code) {
            return routeOriginIcon;
        }

        if (undefined !== request.route[0].out && stop.code === request.route[0].out.code) {
            return routeDestinationIcon;
        }

        return stopIcon;
    }

    determineWalkingSubTripIcon(changePreferences) {
        switch (changePreferences) {
            case 1:
                return "icons/bicycle.png";

            default:
                return "icons/walking.png";
        }
    }

    determineTransportType(providerName) {
        const { providers } = this.state;

        const transportType = providers.find(p => p.name === providerName).transportType;

        switch (transportType) {
            case "Metropolitano":
                return "icons/subway.png";
            case "Comboios":
                return "icons/train.png";
            default:
                return "icons/bus.png";
        }
    }

    determineLineCode(trip) {
        const subtrip = trip.subTrips.find(st => undefined !== st && null !== st.lineCode && undefined !== st.lineCode);
        if (undefined !== subtrip) {
            return subtrip.lineCode;
        }

        return "";
    }

    determinePolylineColor(subtrip) {
        return !subtrip.isWalking && "rgb(255,255,255)" !== subtrip.formattedLineBackColor && "rgb(0,0,0)" !== subtrip.formattedLineBackColor ?
            subtrip.formattedLineBackColor :
            "#0d4752";
    }

    switchDirections() {
        const { request } = this.state;
        const origin = request.route[0].in;
        const destination = request.route[0].out;

        //Reset all
        request.route[0].in = undefined;
        request.route[0].out = undefined;
        this.originSearchBarRef.current.updateSearchTextWithoutSearching("");
        this.destinationSearchBarRef.current.updateSearchTextWithoutSearching("");
        //----

        if (undefined === origin && undefined === destination) {
            return null;
        }

        if (undefined !== origin) {
            request.route[0].out = origin;
            this.destinationSearchBarRef.current.updateSearchTextWithoutSearching(origin.name);
        }

        if (undefined !== destination) {
            request.route[0].in = destination;
            this.originSearchBarRef.current.updateSearchTextWithoutSearching(destination.name);
        }

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () =>
            this.renderRoutePath()
        );
    }

    setStartDate(date) {
        const { request } = this.state;

        request.startTime = date;
        request.endTime = undefined;

        this.setState({
            request: request,
            timeFilterSelection: "Agora",
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false
        });
    }

    handleSelectedDepartureDate(date) {
        this.setState({
            selectedDepartureDate: date,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: true
        });
    }

    handleSelectedDepartureTime(time) {
        const { request, selectedDepartureDate } = this.state;

        const newDateObject = new Date(selectedDepartureDate.year, selectedDepartureDate.month - 1, selectedDepartureDate.day, time.hour, time.minute);
        request.startTime = newDateObject;
        request.endTime = undefined;

        this.setState({
            selectedDepartureTime: { hour: time.hour, minute: time.minute },
            request: request,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            timeFilterSelection: "Partida"
        });
    }

    handleSelectedArrivalDate(date) {
        this.setState({
            selectedArrivalDate: date,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: true
        });
    }

    handleSelectedArrivalTime(time) {
        const { request, selectedArrivalDate } = this.state;

        const newDateObject = new Date(selectedArrivalDate.year, selectedArrivalDate.month - 1, selectedArrivalDate.day, time.hour, time.minute);
        request.endTime = newDateObject;
        request.startTime = undefined;

        this.setState({
            selectedArrivalTime: { hour: time.hour, minute: time.minute },
            request: request,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false,
            timeFilterSelection: "Chegada"
        });
    }

    determineSelectedTime() {
        const { request, timeFilterSelection } = this.state;

        switch (timeFilterSelection) {
            case "Agora":
                return strings.planRouteTimeFilterSelectNow;
            case "Partida":
                return `${strings.planRouteTimeFilterSelectedStartDate} ${request.startTime.toLocaleString(strings.locale)}`;
            case "Chegada":
                return `${strings.planRouteTimeFilterSelectedEndDate} ${request.endTime.toLocaleString(strings.locale)}`;
            default:
                return strings.planRouteTimeFilterSelectNow;
        }
    }

    determineTimePickerLabel(time) {
        if (null === time) {
            const now = new Date();
            return {
                hour: now.getHours(),
                minute: now.getMinutes()
            }
        }

        return time;
    }

    buildTripsPolylines(trips) {
        if (!Array.isArray(trips) || 0 === trips.length) {
            return;
        }

        const lastTripIndex = trips.length - 1;
        const polylines = trips.slice()
            .reduce(
                (output, trip, tripIdx) =>
                    output.concat(trip.subTrips.map((subTrip, subTripIdx) => this.renderPolyLine(subTrip, lastTripIndex !== tripIdx ? "gray" : this.determinePolylineColor(subTrip),
                        tripIdx, subTripIdx))),
                []
            )
            .reverse();

        this.setState({
            polylines: polylines
        });
    }

    //RENDER FUNCTIONS
    renderPolyLine(subTrip, color, tripIdx, subTripIdx) {
        if (Array.isArray(subTrip.breakpoints) && 0 < subTrip.breakpoints.length) {
            return (
                <Polyline key={`polyline-${tripIdx}-${subTripIdx}`}
                    positions={subTrip.breakpoints.map(breakpoint => [breakpoint.latitude, breakpoint.longitude])}
                    color={color}
                    dashArray={subTrip.isWalking ? "2 8" : null} />
            );
        }

        return (
            <Polyline key={`polyline-${tripIdx}-${subTripIdx}`}
                positions={subTrip.passings.map(passing => [passing.x, passing.y])}
                color={color}
                dashArray={subTrip.isWalking ? "2 8" : null} />
        );
    }


    renderPlanRouteInfo() {
        const { requestPreferences, requestChangePreferences, availableTransportTypes, requestTransportTypes, request } = this.state;

        return (
            <form className="plan-route-form-panel" onSubmit={(e) => this.planRoute(e)}>
                <div className="plan-route-form-panel-img">
                    <img className="margin-right-5 align-self-center" src="icons/planroute_start_stop_icon.png" alt="" height="75px" />
                </div>

                {this.renderStopSelectionPanel()}
                {this.renderFiltersPanel()}

                <div className="plan-route-plan-route-button-panel">
                    <FilterButton
                        className="margin-left-5"
                        title={strings.optionsButtonTooltip}
                        callback={(a, b) => this.filterCallback(a, b)}
                        requestPreferences={requestPreferences}
                        request={request}
                        requestChangePreferences={requestChangePreferences}
                        availableTransportTypes={availableTransportTypes}
                        requestTransportTypes={requestTransportTypes}
                        handlePreferencesChange={this.handlePreferencesChange}
                        handleChangePreferencesChange={this.handleChangePreferencesChange}
                        handleTransportTypesChange={this.handleTransportTypesChange}
                    />
                    <Button className="margin-left-10" type="submit">{strings.planRoute}</Button>
                </div>
            </form>
        );
    }

    renderStopSelectionPanel() {
        const { useUserLocation } = this.state;

        return (
            <div className="plan-route-stop-selection-panel">
                <div className="d-flex">
                    <div className="plan-route-info-search-bar-panel" style={{ marginRight: useUserLocation ? "0px" : "45px" }}>
                        <SearchBar
                            ref={this.originSearchBarRef}
                            className="plan-route-info-search-bar"
                            resultsClassName="plan-route-search-bar-results-list"
                            caller="planroute-startstop"
                            placeholder={strings.originStopPlaceHolder}
                            onSelect={(stop) => this.handleStartStopSelection(stop)}
                            onSearchClear={() => this.handleStartSearchBarClear()}
                            isRequired={true} />
                    </div>
                    {
                        useUserLocation ?
                            <UserLocationAsOriginButton className="margin-left-5" getUserLocation={() => this.updateUserLocation()} />
                            :
                            null
                    }
                </div>

                <div className="d-flex">
                    <div className="plan-route-info-search-bar-panel">
                        <SearchBar
                            ref={this.destinationSearchBarRef}
                            className="plan-route-info-search-bar"
                            resultsClassName="plan-route-search-bar-results-list"
                            caller="planroute-endstop"
                            placeholder={strings.destinationStopPlaceHolder}
                            onSelect={(stop) => this.handleEndStopSelection(stop)}
                            onSearchClear={() => this.handleEndSearchBarClear()}
                            isRequired={true} />
                    </div>
                    <SwitchDirectionButton
                        className="margin-left-5"
                        title={strings.reverseDirectionTooltip}
                        switchDirections={() => this.switchDirections()}
                    />
                </div>
            </div>
        );
    }

    renderFiltersPanel() {
        return (
            <div className="plan-route-filters-panel">
                <div className="plan-route-filters-panel-dropdowns" style={{ justifyContent: "end" }}>
                    <div className="dropdown">
                        <Button variant="secondary" className="dropdown-toggle plan-route-filter-button" id="timeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex">
                                <div className="icon-clock" />
                                {this.determineSelectedTime()}
                            </div>
                        </Button>
                        <ul className="dropdown-menu width-100" aria-labelledby="timeDropdown">
                            <li onClick={() => this.setStartDate(new Date())}>
                                <span className="dropdown-item">{strings.planRouteTimeFilterSelectNow}</span>
                            </li>
                            <li onClick={() => this.handleSelectStartDateOptionClick()}>
                                <span className="dropdown-item">{strings.planRouteTimeFilterSelectStartDate}</span>
                            </li>
                            <li onClick={() => this.handleSelectEndDateOptionClick()}>
                                <span className="dropdown-item">{strings.planRouteTimeFilterSelectEndDate}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                {this.renderDepartureDateTimePicker()}
                {this.renderArrivalDateTimePicker()}
            </div>
        );
    }

    renderDepartureDateTimePicker() {
        const { selectedDepartureDate, selectedDepartureTime, displayDepartureDateDatePicker, displayDepartureDateTimePicker } = this.state;

        if (displayDepartureDateDatePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <DatePicker
                        value={selectedDepartureDate}
                        locale={strings.locale}
                        onChange={(e) => this.handleSelectedDepartureDate(e)}
                        colorPrimary="#3167a4"
                        inputPlaceholder={strings.planRouteTimeFilterSelectStartDate}
                        inputName="plan-route-departure-date-picker-input"
                        inputClassName="plan-route-date-picker-input"
                        shouldHighlightWeekends
                    />
                </div>
            );
        }

        if (displayDepartureDateTimePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <TimeKeeper
                        time={this.determineTimePickerLabel(selectedDepartureTime)}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect
                        forceCoarseMinutes
                        onDoneClick={(time) => this.handleSelectedDepartureTime(time)}
                        doneButton={(time) => (
                            <div className="plan-route-time-picker-done-button" onClick={() => this.handleSelectedDepartureTime(time)}>
                                {strings.done}
                            </div>
                        )}
                    />
                </div>
            );
        }

        return null;
    }

    renderArrivalDateTimePicker() {
        const { selectedArrivalDate, selectedArrivalTime, displayArrivalDateDatePicker, displayArrivalDateTimePicker } = this.state;

        if (displayArrivalDateDatePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <DatePicker
                        value={selectedArrivalDate}
                        locale={strings.locale}
                        onChange={(e) => this.handleSelectedArrivalDate(e)}
                        colorPrimary="#3167a4"
                        inputPlaceholder={strings.planRouteTimeFilterSelectEndDate}
                        inputName="plan-route-arrival-date-picker-input"
                        inputClassName="plan-route-date-picker-input"
                        shouldHighlightWeekends
                    />
                </div>
            );
        }

        if (displayArrivalDateTimePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <TimeKeeper
                        time={this.determineTimePickerLabel(selectedArrivalTime)}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect
                        forceCoarseMinutes
                        onDoneClick={(time) => this.handleSelectedArrivalTime(time)}
                        doneButton={(time) => (
                            <div className="plan-route-time-picker-done-button" onClick={() => this.handleSelectedArrivalTime(time)}>
                                {strings.done}
                            </div>
                        )}
                    />
                </div>
            );
        }

        return null;
    }

    renderTrips() {
        const { trips, planningRoutes, displayResults } = this.state;

        //If not loading, but selected stop or selected stop passings are undefined, return nothing
        if (!displayResults) {
            return null;
        }

        return (
            <div className="plan-route-trips-panel">
                <div className="plan-route-trips-panel-header d-flex justify-content-between">
                    <div className="d-flex-inline text-truncate">
                        <b>{strings.plannedRoutes}</b>
                    </div>
                    <div className="icon-refresh2 refresh-button" onClick={(e) => this.planRoute(e)} />
                </div>

                <ListGroup className="trips-list">
                    {
                        planningRoutes ?
                            <ListGroupItem key="plan-route-spinner" className="text-align-center">
                                <Spinner animation="border" role="status" />
                            </ListGroupItem>
                            :
                            this.renderTripsInfo(trips)}
                </ListGroup>

            </div>
        );
    }

    renderTripsInfo(trips) {
        if (0 === trips.length) {
            return (
                <ListGroupItem key={`trip-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noInfoToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        const { resultChangePreferences } = this.state;

        return (
            trips.map((trip, index) =>
                <ListGroupItem key={`trip-${index}`} onClick={() => this.handleTripSelected(trip)}>
                    <Row>
                        <Col xs={7} sm={9} className="plan-route-trips-list-trip-header-route-info">
                            {this.renderTripChanges(resultChangePreferences, trip)}
                        </Col>
                        <Col xs={5} sm={3} className="plan-route-trips-list-trip-header-duration-info">
                            <div>
                                {strings.originStopPlaceHolder}: {trip.departureTimeToShow}
                            </div>
                            <div>
                                {strings.destinationStopPlaceHolder}: {trip.arrivalTimeToShow}
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            )
        );
    }

    renderTripChanges(changePreferences, trip) {
        const lastSubTripIndex = trip.subTrips
            .length - 1;
        const numberNonWalkingSubtrips = trip.subTrips
            .reduce((output, subtrip) => subtrip.isWalking ? output : output + 1, 0);

        return (
            <div>
                <div className="plan-route-trips-list-trip-header-changes-info">
                    {
                        trip.subTrips
                            .filter((subtrip, index) => 0 === index || lastSubTripIndex === index || !subtrip.isWalking || parseInt(subtrip.walkingTime) > 2)
                            .reduce((output, subtrip, index) => [...output, ...this.renderSubtripChangesInfo(changePreferences, subtrip, index)], [])
                            .slice(0, -1)
                    }
                </div>
                {
                    numberNonWalkingSubtrips > 1 ?
                        <div>
                            {strings.includes} {numberNonWalkingSubtrips - 1} {strings.changes}
                        </div>
                        :
                        null
                }
            </div>
        );
    }

    renderSubtripChangesInfo(changePreferences, subtrip, index) {
        const { getLogo } = this.context;
        const components = [];

        if (subtrip.isWalking) {
            components.push(<img key={`subtrip-${index}-walking`} src={this.determineWalkingSubTripIcon(changePreferences)} alt="" height="30px" />);
        } else {
            components.push(
                <div key={`subtrip-${index}-provider-change`} className="display-contents">
                    <img key={`subtrip-${index}-provider-logo`} src={getLogo(subtrip.provider)} alt="" height="15px" />
                    &nbsp;
                    <div className="d-flex-inline white-space-no-wrap"><b className="trip-line-code" /*style={{ background: subtrip.formattedLineBackColor, color: subtrip.formattedLineForeColor }}*/>{subtrip.lineCode}</b></div>
                </div>
            );
        }

        components.push(<div key={`subtrip-${index}-next-change`} className="icon-menu" />);

        return components;
    }

    renderRoutePath() {
        const { request } = this.state;

        if (undefined !== request.route[0].in && undefined !== request.route[0].out) {
            const positions = [
                [request.route[0].in.coordX, request.route[0].in.coordY],
                [request.route[0].out.coordX, request.route[0].out.coordY]
            ];


            const polyline = [<Polyline key={`polyline-${positions[0][0]}-${positions[1][1]}`} positions={positions} color="#0d4752" />];
            this.setState({
                polylines: polyline
            });
        }
    }

    renderProviderLogo(stop, height = "auto") {
        const { getLogo } = this.context;
        const imgUrl = getLogo(stop.provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img className="margin-right-5" height={height} src={imgUrl} alt={`${stop.provider}`} />
        );
    }

    render() {
        const { request, polylines, displayResults, planningRoutes, trips } = this.state;

        return (
            <div className="plan-route-info">
                {
                    planningRoutes && !displayResults ?
                        <div className="plan-route-info-loading-panel">
                            <Spinner animation="border" role="status" />
                        </div>
                        :
                        null
                }
                <div className="plan-route-info-panel align-items-center">
                    {this.renderPlanRouteInfo()}
                    {this.renderTrips()}
                </div>

                <MapContainer className="map-container" bounds={this.getBounds()} zoom={16} scrollWheelZoom={true} whenReady={mapInstance => { this.mapRef.current = mapInstance }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />

                    {
                        undefined !== request.route[0].in ?
                            <DraggableMarker
                                markerKey={`route-origin`}
                                position={[request.route[0].in.coordX, request.route[0].in.coordY]}
                                icon={routeOriginIcon}
                                tooltip={
                                    <Tooltip>
                                        {this.renderProviderLogo(request.route[0].in, "15px")}
                                        <strong>{request.route[0].in.name}</strong>
                                        ({request.route[0].in.code})
                                    </Tooltip>
                                }
                                handleDrag={(mapPoint) => this.handleOriginDrag(mapPoint)}
                            />
                            :
                            null
                    }

                    {
                        undefined !== request.route[0].out ?
                            <DraggableMarker
                                markerKey={`route-destination`}
                                position={[request.route[0].out.coordX, request.route[0].out.coordY]}
                                icon={routeDestinationIcon}
                                tooltip={
                                    <Tooltip>
                                        {this.renderProviderLogo(request.route[0].out, "15px")}
                                        <strong>{request.route[0].out.name}</strong>
                                        ({request.route[0].out.code})
                                    </Tooltip>
                                }
                                handleDrag={(mapPoint) => this.handleDestinationDrag(mapPoint)}
                            />
                            :
                            null
                    }
                    <Pane>
                        {polylines}
                    </Pane>

                    {
                        displayResults && trips.length !== 0 ?
                            <SetBounds bounds={this.getBounds()} />
                            :
                            null
                    }
                    <MapEvents handleMapPointSelection={(mapPoint) => this.handleMapPointSelection(mapPoint)} />
                </MapContainer>
            </div>
        );
    }
}

function SetBounds({ bounds }) {
    const map = useMap();
    const mapSize = map.getSize();

    if (window.screen.availWidth < 1024) {
        map.fitBounds(bounds, { paddingTopLeft: [0, 600] });
    } else {
        map.fitBounds(bounds, { paddingTopLeft: [mapSize.x / 2, 0] });
    }
    return null;
}

function MapEvents(args) {
    const map = useMap();
    useMapEvents({
        dragend: (e) => {
            map.setView(e.target.getCenter(), map.getZoom());
        },
        click: (e) => {
            //If it was fired programatically
            if (e.forcedEvent) {
                map.setView({ lat: e.latlng.lat, lng: e.latlng.lng }, map.getZoom());
            } else {
                args.handleMapPointSelection({ lat: e.latlng.lat, lng: e.latlng.lng });
            }
        }
    });
    return null;
}