import React, { Component } from 'react';
import { strings } from '../../resources/strings';

export class Privacy extends Component {
    //RENDER

    render() {
        return (
            <div className="about-view">
                <h1>{strings.rgpd}</h1>
                <h4>{strings.privacy_policy}</h4>
                <p>{strings.privacy_policy_description}</p>

                <b>{strings.privacy_policy_bold_1}</b>

                <h3>{strings.title_what}</h3>
                <p>{strings.text_what}</p>

                <h3>{strings.title_how}</h3>
                <p>{strings.text_how}</p>

                <h3>{strings.title_plan}</h3>
                <p>{strings.text_plan}</p>

                <h3>{strings.title_info}</h3>
                <p>{strings.text_info}</p>
            </div>
        );
    }
}
