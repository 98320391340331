import React, { Component } from 'react';

export class UserLocationAsOriginButton extends Component {
    render() {
        const { className, getUserLocation } = this.props;

        return (
            <div className={className}>
                <button type="button" className="user-location-as-origin-button" onClick={() => getUserLocation()}>
                    <div className="icon-dot-circled" />
                </button>
            </div>
        );
    }
}