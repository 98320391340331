import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

export class SearchBarResults extends Component {
    static contextTypes = {
        getLogo: PropTypes.func
    };

    renderResultName(result) {
        if (1 !== result.type) {
            return `${result.name}`;
        }
        return `${result.name} (${result.code})`;
    }

    renderProviderLogo(result) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(result.provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img src={imgUrl} height="20px" alt={`${null != result.provider ? result.provider : ""}`} />
        );
    }

    render() {
        const { className, data, onSelect } = this.props;
        return (
            <div className="search-bar-results-panel">
                <ListGroup className={className}>
                    {
                        data.map((result, index) =>
                            <ListGroupItem key={`search-result-${index}`} onClick={() => onSelect(result, true)}>
                                <div className="search-bar-result-info">
                                    {this.renderProviderLogo(result)}
                                    {this.renderResultName(result)}
                                </div>
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}