import L from 'leaflet';

const stopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/stopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const busStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/busIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedBusStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedBusStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const trainStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/trainIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedTrainStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedTrainStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const boatStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/boatIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedBoatStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedBoatStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const planeStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/planeIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedPlaneStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedPlaneStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const uncategorizedPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/uncategorizedPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedUncategorizedPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedUncategorizedPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const ticketOfficePoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/ticketOfficePoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedTicketOfficePoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedTicketOfficePoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const bikePoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/bikePoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedBikePoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedBikePoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const bikeParkingPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/bikeParkingPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedBikeParkingPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedBikeParkingPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const boatPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/boatPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedBoatPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedBoatPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const scooterPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/scooterPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedScooterPoiIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedScooterPoiIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const eVChargerIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/eVChargerIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const selectedEVChargerIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedEVChargerIcon.png'),
    iconAnchor: new L.Point(20, 20),
    iconSize: new L.Point(40, 40)
});

const lineInfoStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const lineInfoSelectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoSelectedStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const routeOriginIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeOriginIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const routeDestinationIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeDestinationIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

export {
    stopIcon, selectedStopIcon, busStopIcon, selectedBusStopIcon, trainStopIcon, selectedTrainStopIcon, boatStopIcon, selectedBoatStopIcon,
    planeStopIcon, selectedPlaneStopIcon, lineInfoStopIcon, lineInfoSelectedStopIcon, routeOriginIcon, routeDestinationIcon,
    uncategorizedPoiIcon, selectedUncategorizedPoiIcon, ticketOfficePoiIcon, selectedTicketOfficePoiIcon,
    bikePoiIcon, selectedBikePoiIcon, bikeParkingPoiIcon, selectedBikeParkingPoiIcon, boatPoiIcon, selectedBoatPoiIcon, scooterPoiIcon, selectedScooterPoiIcon,
    eVChargerIcon, selectedEVChargerIcon
}