import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { strings } from '../../../resources/strings';

export class FilterMenu extends Component {

    render() {
        const {
            requestPreferences,
            request,
            requestChangePreferences,
            availableTransportTypes,
            requestTransportTypes,
            handlePreferencesChange,
            handleChangePreferencesChange,
            handleTransportTypesChange
        } = this.props;

        return (
            <div className="filter-options">
                <div className="filter-menu-header">
                    <div className="d-flex-inline text-truncate">
                        <b>{strings.filters}</b>
                    </div>
                </div>
                <div className="plan-route-filter-menu">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="col-left">
                                <span className="plan-route-options-panel-header">{strings.planRouteTypeOfTrip}</span>
                            </div>
                            <div className="d-grid">
                                {Object.entries(requestPreferences).map(object =>
                                    <div key={`${object[0]}-${strings[object[1]]}`} className="plan-route-filter-options">
                                        <label style={{ marginLeft: '15px' }}>
                                            <input
                                                type="radio"
                                                name="routeOption"
                                                value={object[0]}
                                                id={object[0]}
                                                checked={object[0] === `${request.preferences}`}
                                                onChange={(e) => handlePreferencesChange(e)}
                                            /> {strings[object[1]]}
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-left">
                                <span className="plan-route-options-panel-header">{strings.planRouteTranshipments}</span>
                            </div>
                            <div className="d-grid">
                                {Object.entries(requestChangePreferences).map(object =>
                                    <div key={`${object[0]}-${strings[object[1]]}`} className="plan-route-filter-options">
                                        <label style={{ marginLeft: '15px' }}>
                                            <input
                                                type="radio"
                                                name="routeChangeOption"
                                                value={object[0]}
                                                id={object[0]}
                                                checked={object[0] === `${request.changePreferences}`}
                                                onChange={(e) => handleChangePreferencesChange(e)}
                                            /> {strings[object[1]]}
                                        </label>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="col-left">
                                <span className="plan-route-options-panel-header">{strings.transports}</span>
                            </div>
                            <div className="plan-route-filter-options">
                                {Object.entries(availableTransportTypes).map(object => (
                                    <div key={`${object[0]}-${strings[object[1]]}`} className="plan-route-filter-options">
                                        <label style={{ marginLeft: '15px' }}>
                                            <input
                                                type="checkbox"
                                                name={object[1]}
                                                value={object[0]}
                                                id={object[0]}
                                                checked={requestTransportTypes.findIndex(tt => `${tt}` === object[0]) !== -1}
                                                onChange={(e) => handleTransportTypesChange(e)}
                                            /> {strings[object[1]]}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
