import React, { Component } from 'react';
import { FilterOption } from './FilterOption';
import { strings } from '../../../resources/strings';

export class FilterMenu extends Component{

  onTransportFilterClick(key, filterOptions, callback){
    filterOptions.transports[key] = !filterOptions.transports[key];
    callback(filterOptions, true);
  }

  onMobilityFilterClick(key, filterOptions, callback){
    filterOptions.mobility[key] = !filterOptions.mobility[key];
    callback(filterOptions, false);
  }
  
  render(){
    const { filterOptions, callback } = this.props;
    
    let transportsRender = [];
    Object.keys(filterOptions.transports).forEach((i)=>
      transportsRender.push(
        <FilterOption 
          key={`filter-option-${i}`}
          name={i} 
          options={filterOptions} 
          callback={callback}
          onClick={this.onTransportFilterClick}
        />
      )
    )

    let mobilityRender = [];
    Object.keys(filterOptions.mobility).forEach((i)=>
      mobilityRender.push(
        <FilterOption
          key={`filter-option-${i}`} 
          name={i} 
          options={filterOptions} 
          callback={callback}
          onClick={this.onMobilityFilterClick}
        />
      )
    )

    return(
      <div className="filter-menu">
        <div className="filter-menu-header d-flex justify-content-between">
          <div className="d-flex-inline text-truncate">
            <b>{strings.filters}</b>
          </div>
        </div>
        <div className="filter-menu-options">
          <div className="filter-menu-options-transports">
            <div className="filter-menu-options-header">
              <b>{strings.transports}</b>
            </div>
            <div className="filter-menu-options-options">
              {transportsRender}
            </div>
          </div>
          <div className="filter-menu-options-mobility">
            <div className="filter-menu-options-header">
              <b>{strings.mobility}</b>
            </div>
            <div className="filter-menu-options-options">
              {mobilityRender}
            </div>
          </div>
        </div>
      </div>
    )
  }
}