import { LaneAPI } from '../../api/LaneAPI';

export class LaneCommand {
    getLanes(successCallback, errorCallback) {
        const apiResult = LaneAPI.getLanes();
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getNearLanes(latitude, longitude, radius, successCallback, errorCallback) {
        const apiResult = LaneAPI.getNearLanes(latitude, longitude, radius);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}