import React, { Component } from 'react';
import { strings } from '../../../resources/strings';

export class FilterOption extends Component{
  render(){
    const { name, options, onClick, callback } = this.props;

    var selected = options.transports[name] === undefined? options.mobility[name] : options.transports[name];

    return(
      <div onClick={()=>onClick(name, options, callback)} className="filter-menu-option">
        {strings[name]}
        <input className="filter-menu-option-checkbox" type="checkbox" checked={selected} readOnly/>
      </div>
    )
  }
}