import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';


const laneApiRoutes = {
    defaultRoute: "lane/"
};

export class LaneAPI {
    static getLanes() {
        const url = `${laneApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getNearLanes(latitude, longitude, radius) {
        const url = `${laneApiRoutes.defaultRoute}${latitude}/${longitude}/${radius}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}