import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        reverseDirectionTooltip: "Reverse direction",
        recenterTooltip: "Recenter",
        options: "Options",
        nextDepartures: "Network",
        planRoute: "Plan Route",
        schedules: "Schedules",
        about: "About",
        searchPlaceholder: "Select a stop",
        originStopPlaceHolder: "Departure",
        destinationStopPlaceHolder: "Arrival",
        plannedRoutes: "Trip Options",
        includes: "Includes",
        changes: "change(s)",
        walkAround: "Go around",
        estimatedWaitingTime: "Waiting period between",
        arrivalEstimatedAt: "Arrival estimated at",
        and: "and",
        duration: "Duration",
        userLocationPlace: "Your location",
        bus: "Bus",
        train: "Train",
        boat: "Fluvial",
        plane: "Plane",
        road: "Road",
        rail: "Rail",
        fluvial: "River",
        aerial: "Aerial",
        cycling: "Bikeway",
        OptimiseArrivalTime: "Fastest",
        OptimiseChanges: "Least transfers",
        OptimiseWalkTime: "Least walking distance",
        OptimiseBicycleTime: "Less cycling",
        OptimiseScooterTime: "Less electric scooter travel",
        OptimiseTourism: "Most touristic",
        planRouteTypeOfTrip: "Type of Trip",
        planRouteTranshipments: "Transhipments",
        planRouteTimeFilterSelectNow: "Now",
        planRouteTimeFilterSelectStartDate: "Select departure date",
        planRouteTimeFilterSelectedStartDate: "Departure at",
        planRouteTimeFilterSelectEndDate: "Select arrival date",
        planRouteTimeFilterSelectedEndDate: "Arrival at",
        optionsButtonTooltip: "More options",
        switchDirectionsButtonTooltip: "Reverse direction",
        userLocationButtonTooltip: "Use your location",
        fares: "Fares",
        lineInfoStopsTitle: "Stops",
        lineInfoMoreScheds: "more schedules",
        showStops: "Show stops",
        showSchedule: "Show schedule",
        showTrip: "Show route",
        showMap: "Show map",
        moreSchedsTitle: "Schedules",
        noPassingsToShow: "No passings to show",
        noTripsToShow: "No trips to show",
        noInfoToShow: "No information to show",
        notes: "View Notes",
        selectDate: "Select date",
        provider: "Provider",
        line: "Line",
        direction: "Direction",
        directionGo: "Go",
        directionReturn: "Return",
        directionCircular: "Circular",
        daytype: "Day Type",
        locale: "en",
        hoursFilter: "Filter by hours",
        initialHourFilter: "Start time",
        finalHourFilter: "End time",
        filter: "Filter",
        close: "Close",
        done: "Done",
        copyright: "Powered by OPT",
        allRightsReserved: "Developed for Comunidade Intermunicipal do Algarve. All rights reserved",
        privacyPolicy: "Privacy Policy",
        pageNotFound: "Page not found",
        cofundedBy: "Cofunded by:",
        onFoot: "By foot",
        onBike: "By bike",
        onScooter: "By scooter",
        from: "From",
        to: "to",
        transportTypeFilter: "Select the means of transportation",

        //PRIVACY POLICY
        rgpd: "RGPD",
        privacy_policy: "PRIVACY POLICY",
        privacy_policy_description: "",
        privacy_policy_bold_1: "",

        //ABOUT US
        title_who: "Who we are",
        title_what: "What we do",
        title_how: "How we do it",
        title_plan: "Plan your itinerary",
        title_info: "In real time information",

        text_who: "A CI-AMAL – Comunidade Intermunicipal do Algarve is a collective person governed by public law, with the nature of public association freely instituted by the sixteen counties of the Algarve region, in accordance with the Statute of Intermunicipal Entities, approved by the Law n.º 75/2013, of 12/09, headquartered at Rua General Humberto Delgado, n.º 20, 8000 - 355 Faro, district, county and union of parishes of Faro, and tax payer under the number 502 971 096. It's the intercity Transport Authority of the Algarve region (and local county through assumption of competences delegated by the counties), as a public entity with attributions and competences in termos of organisation, exploration, assignment, investment, financing, supervision, monitoring and divulgation of the regular public service of road and river transportation of passengers and in matter of contractualization and determination of public service obligations and tariffs.",
        text_what: "Mobility is the ease of movement of people and goods across the territory, in the development of economic and social activity, carried out by means of motorized and non- motorised vehicles, through the infrastructure.This makes it possible to exercise the right to come and go in everyday life, in a fair and dignified way, generating countless possibilities and enhancing the conquest of new opportunities, regardless of where you were born or where you live.The quality of mobility is intrinsically related to the articulation of the different transport types.CI - AMAL is committed to helping the Algarve region in the continuous improvement of people's quality of life, overcoming limitations and obstacles of mobility across the territory. Helping in the development of a cleaner, safer and better place to live.",
        text_how: "We help people get where they want to be in the simplest and easiest way, providing a planning of trips that combine multiple transportation options, with in real-time information, directly accessible in the NESO app. We work with the Municipal Transport Authorities and with the IMT, I.P., with the operators of public transport and companies to overcome the challenges of mobility, to implement artificial intelligence solutions in the planning, operation and evaluation of the trip. The NESO app tools improve the attractiveness of public transport, mitigate congestion, increase the efficiency of the entire system, making more people use public transport, micromobility, soft mobility and facilitate electric mobility. We create technology for people in Algarve, with a very positive impact on local communities.",
        text_plan: "Find the best route. Plan your trip as you like. Our multimodal trip planner displays the best mobility options available in the region, letting you pick the route to follow, choose the start and arrival points and discover several available traveling options.",
        text_info: "The NESO app is the first and only integrated and dedicated app for the Algarve region. Here you find all mobility options (bus, train, bicycles, scooters, etc.), in a single application: Don't waste time waiting for the bus, train or boat. Know in advance what time your type of transport will arrive at a stop or station near you. Check exactly where your bus is located on the map and it's arrival time! Easily find public transport lines, view bike lanes, bike parks, bicycle renting shops and find parks with bicycles available. Cycle through up-to-date routes and discover what Algarve has to offer. Discover all the useful information in the NESO app and obtain all the necessary data to be able to move with more freedom and ease through the territory of Algarve!",

        //FILTER
        filters: "Filters",
        transports: "Type of Transport",
        mobility: "Mobility",
        electric: "Electric",
        soft: "Soft",
        micro: "Micro",
    },
    pt: {
        reverseDirectionTooltip: "Inverter direção",
        recenterTooltip: "Recentrar",
        options: "Opções",
        nextDepartures: "Rede",
        planRoute: "Planear Rota",
        schedules: "Horários",
        about: "Acerca de",
        searchPlaceholder: "Selecione uma paragem",
        originStopPlaceHolder: "Partida",
        destinationStopPlaceHolder: "Chegada",
        plannedRoutes: "Opções de Viagem",
        includes: "Inclui",
        changes: "transbordo(s)",
        walkAround: "Ir cerca de",
        estimatedWaitingTime: "Período de espera entre",
        arrivalEstimatedAt: "Chegada estimada às",
        and: "e",
        duration: "Duração",
        userLocationPlace: "A sua localização",
        bus: "Autocarro",
        train: "Comboio",
        boat: "Barco",
        plane: "Avião",
        road: "Rodoviário",
        rail: "Ferroviário",
        fluvial: "Fluvial",
        aerial: "Aéreo",
        cycling: "Ciclovia",
        OptimiseArrivalTime: "Mais rápido",
        OptimiseChanges: "Menos transbordos",
        OptimiseWalkTime: "Menos percurso a pé",
        OptimiseBicycleTime: "Menos percurso de bicicleta",
        OptimiseScooterTime: "Menos percurso de trotinete",
        OptimiseTourism: "Mais turístico",
        planRouteTypeOfTrip: "Tipo de Viagem",
        planRouteTranshipments: "Transbordos",
        planRouteTimeFilterSelectNow: "Agora",
        planRouteTimeFilterSelectStartDate: "Escolher data de partida",
        planRouteTimeFilterSelectedStartDate: "Partida às",
        planRouteTimeFilterSelectEndDate: "Escolher data de chegada",
        planRouteTimeFilterSelectedEndDate: "Chegada às",
        optionsButtonTooltip: "Mais opções",
        switchDirectionsButtonTooltip: "Inverter sentido",
        userLocationButtonTooltip: "Utilizar a sua localização",
        fares: "Tarifários",
        lineInfoStopsTitle: "Paragens",
        lineInfoMoreScheds: "mais horários",
        showStops: "Ver paragens",
        showSchedule: "Ver horário",
        showTrip: "Ver viagem",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horários",
        noPassingsToShow: "Sem tempos de passagem",
        noTripsToShow: "Sem viagens para mostrar",
        noInfoToShow: "Sem informação para mostrar",
        notes: "Ver Notas",
        selectDate: "Seleccione uma data",
        provider: "Operador",
        line: "Linha",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Volta",
        directionCircular: "Circular",
        daytype: "Tipo de Dia",
        locale: "pt",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        filters: "Filtros",
        close: "Fechar",
        done: "Concluído",
        copyright: "Powered by OPT",
        allRightsReserved: "Desenvolvido para Comunidade Intermunicipal do Algarve. Todos os direitos reservados",
        privacyPolicy: "Política de Privacidade",
        pageNotFound: "Página não encontrada",
        cofundedBy: "Cofinanciamento por:",
        onFoot: "A pé",
        onBike: "De bicicleta",
        onScooter: "De trotinete",
        from: "De",
        to: "a",
        transportTypeFilter: "Selectione o modo de transporte",

        //PRIVACY POLICY
        rgpd: "RGPD",
        privacy_policy: "POLÍTICA DE PRIVACIDADE",
        privacy_policy_description: "O presente documento assume o compromisso da AMAL – Comunidade Intermunicipal do Algarve, para com todos os titulares de dados que trata na qualidade de Responsável pelo Tratamento de Dados Pessoais, do respeito pelas regras do Regulamento Geral de Proteção de Dados, Regulamento Europeu n.º 2016/679 (doravante RGPD) e da Lei de Execução Nacional (Lei n.º 59/2019, de 8 de Agosto) no tratamento de dados pessoais.",
        privacy_policy_bold_1: "A mudança de paradigma introduzida pelo Regulamento Geral de Proteção de Dados – RGPD",
        privacy_policy_paragraph_1: "Não obstante este compromisso e respeito pelas regras e obrigações no tratamento de dados pessoais já existisse antes de 25 de maio de 2018, com entrada em vigor do RGPD e, mais tarde, com a Lei de Execução Nacional que densificou esta norma na ordem jurídica nacional, estas regras assumiram uma relevância maior e a AMAL reforçou o seu compromisso com o respeito pela privacidade e a garantia do respeito pelo regime de tratamento de dados pessoais e pelas obrigações que impõe aos responsáveis pelo tratamento de dados pessoais.",
        privacy_policy_paragraph_2: "Com o objetivo de proteger e garantir o respeito pela privacidade do titular de dados pessoais, o novo regime consagra os seguintes direitos do titular de dados: direito de informação (art. 13.º e 14.º), direito de acesso (art. 15.º), direito de retificação (art. 16.º) direito ao apagamento dos dados “direito a ser esquecido” (art. 17.º), direito à limitação do tratamento (art. 18.º) direito de portabilidade de dados (art.20.º) direito de oposição (art. 21.º) e decisões individuais automatizadas, incluindo definição de perfis (art, 22.º). Pode o titular dos dados exercer estes direitos junto da AMAL, estando esta obrigada a dar-lhes o encaminhamento legal no prazo de 30 dias.",
        privacy_policy_paragraph_3: "Para efeitos deste regime, consideram-se dados pessoais as informações relativas a uma pessoa singular, identificada ou identificável, sendo que considera identificável uma pessoa que possa ser identificada direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular. Estão excluídos do âmbito de aplicação desta lei os dados das pessoas coletivas.",
        privacy_policy_paragraph_4: "Com a entrada em vigor do RGPD e da Lei de Execução Nacional, uma das mais importantes alterações reside na legitimidade que passa a ser necessária para proceder ao tratamento de dados e que, de acordo com o art. 6.º do RGPD assenta em, pelo menos, um dos seguintes fundamentos:",
        privacy_policy_bullet_1: "O titular dos dados tiver dado o seu consentimento para o tratamento dos seus dados pessoais para uma ou mais finalidades específicas;",
        privacy_policy_bullet_2: "O tratamento for necessário para a execução de um contrato no qual o titular dos dados é parte, ou para diligências pré-contratuais a pedido do titular dos dados;",
        privacy_policy_bullet_3: "O tratamento for necessário para o cumprimento de uma obrigação jurídica a que o responsável pelo tratamento esteja sujeito;",
        privacy_policy_bullet_4: "O tratamento for necessário para a defesa de interesses vitais do titular dos dados ou de outra pessoa singular;",
        privacy_policy_bullet_5: "O tratamento for necessário ao exercício de funções de interesse público ou ao exercício da autoridade pública de que está investido o responsável pelo tratamento;",
        privacy_policy_bullet_6: "O tratamento for necessário para efeito dos interesses legítimos prosseguidos pelo responsável pelo tratamento ou por terceiros, exceto se prevalecerem os interesses ou direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais, em especial se o titular for uma criança.",
        privacy_policy_paragraph_5: "A legitimidade da AMAL para proceder ao tratamento de dados pessoais assenta, essencialmente nas alíneas b), c), e) e f) do art. 6.º, n.º 1 do RGPD e, caso estas fontes de legitimidade não se verifiquem, será solicitado o consentimento ao titular.",
        privacy_policy_bold_2: "Recolha de dados pessoais (finalidades)",
        privacy_policy_paragraph_6: "Termos em que, a AMAL assegura que o tratamento dos seus Dados Pessoais é efetuado no âmbito da(s) finalidade(s) para os quais os mesmos foram recolhidos ou para finalidades compatíveis com o(s) propósito(s) inicial(is) para que foram recolhidos, nomeadamente através dos formulários disponíveis online – princípio da finalidade ou outros em suporte físico.",
        privacy_policy_paragraph_7: "Perante isso, assume a AMAL o compromisso de implementar uma cultura de minimização de Dados, em que apenas se recolhe, utiliza e conserva os Dados Pessoais estritamente necessários ao desenvolvimento da sua atividade e à satisfação dos interesses das/dos cidadãs/cidadãos – princípio da minimização.",
        privacy_policy_paragraph_8: "A AMAL respeita o dever de sigilo e confidencialidade, garantindo que não procederá à divulgação ou partilha dos seus Dados Pessoais para fins comerciais ou de publicidade – princípio da segurança e confidencialidade.",
        privacy_policy_paragraph_9: "A AMAL participa em vários projetos conjuntamente com outras entidades, tendo estes a sua própria Politica de Privacidade que respeita na dependência desta.",
        privacy_policy_bold_3: "Transmissão de dados pessoais a terceiros",
        privacy_policy_paragraph_10: "A AMAL somente poderá partilhar os seus Dados Pessoais com terceiros, quando essa partilha resulte do cumprimento de obrigações legais ou se verifique no exercício de funções de interesse público/autoridade pública. Caso não se verifique nenhuma destas situações será expressamente solicitado ao titular o respetivo consentimento nos termos e para os efeitos do art. 6.º, n.º 1, b) e art. 7.º do RGPD.",
        privacy_policy_bold_4: "Conservação dos dados pessoais",
        privacy_policy_paragraph_11: "",

        //ABOUT US
        title_who: "Quem somos",
        title_what: "O que fazemos",
        title_how: "Como fazemos",
        title_plan: "Planeie o seu itinerário",
        title_info: "Informação em tempo real",

        text_who: "A CI-AMAL – Comunidade Intermunicipal do Algarve é uma pessoa coletiva de direito público, com natureza de associação pública livremente instituída pelos dezasseis municípios da região do Algarve, de acordo com o disposto no Estatuto das Entidades Intermunicipais, aprovado pela Lei n.º 75/2013, de 12/09, com sede na rua General Humberto Delgado, n.º 20, 8000 - 355 Faro, distrito, concelho e união de freguesias de Faro, e contribuinte fiscal sob o número 502 971 096. É a Autoridade intermunicipal de Transportes da região do Algarve(e municipal local através da assunção das competências delegadas pelos municípios), enquanto entidade pública com atribuições e competências em matéria de organização, exploração, atribuição, investimento, financiamento, fiscalização, monitorização e divulgação do serviço público regular de transportes rodoviário e fluvial de passageiros e em matéria de contratualização e determinação de obrigações de serviço público e de tarifários.",
        text_what: "A mobilidade é a facilidade de deslocação das pessoas e bens pelo território, no desenvolvimento da atividade económica e social, realizada por meio de veículos motorizados e não motorizados, através da infraestrutura.O que possibilita exercer o direito de ir e vir quotidiano, de forma justa e digna, gerando inúmeras possibilidades e potenciando a conquista de novas oportunidades, independentemente do local onde se nasce e onde se vive. A qualidade da mobilidade relaciona- se intrinsecamente com a articulação dos diferentes modos. A CI- AMAL está empenhada em ajudar a região do Algarve na melhoria contínua da qualidade de vida das pessoas, superando limitações e obstáculos da mobilidade pelo território. Ajudando no desenvolvimento de um território mais limpo, mais seguro e melhor para viver. ",
        text_how: "Ajudamos as pessoas a chegar onde desejam da maneira mais simples e fácil, possibilitando o planeamento das viagens que combinam múltiplas opções de transporte, com informações em tempo real diretamente acessíveis na app NESO. Trabalhamos com as Autoridades municipais de Transportes e com o IMT, I.P., com os operadores de transporte público e empresas para superar os desafios da mobilidade, para implementar soluções de inteligência artificial no planeamento, na operação e na avaliação da viagem. As ferramentas da app NESO melhoram a atratividade do transporte público, mitigam congestionamentos, aumentam a eficiência de todo o sistema, fazendo com que mais pessoas usem o transporte público, a micromobilidade, a mobilidade suave e facilitam a mobilidade elétrica. Criamos tecnologia para pessoas no Algarve, com impacto muito positivo nas comunidades locais.",
        text_plan: "Encontre o melhor percurso. Planeie a sua viagem como preferir. O nosso planeador multimodal de viagens exibe as opções de mobilidade disponíveis na região, cabendo - lhe apenas optar pela rota a seguir, situando - se no ponto de partida e selecionando o ponto de chegada e descubra as diversas opções de deslocação disponíveis.",
        text_info: "A app NESO é a primeira e a única integrada e dedicada à região do Algarve. Aqui encontra todas as opções de mobilidade(autocarro, comboio, bicicletas, trotinetas, etc.), numa única aplicação: Não perca tempo à espera do autocarro, do comboio, ou do barco.Saiba previamente a que hora o seu modo de transporte vai chegar, a uma paragem ou estação perto de si. Verifique exatamente onde se encontra o seu autocarro no mapa e a sua hora de chegada! Encontre facilmente as linhas de transporte público, visualize ciclovias, parques de bicicletas, lojas de aluguer de bicicletas e encontre e parques com bicicletas disponíveis. Pedale por rotas atualizadas e descubra o que o Algarve tem para oferecer. Descubra toda a informação útil da app NESO e obtenha todos os dados necessários para poder mover - se com maior liberdade e facilidade pelo território do Algarve!",

        //FILTER
        transports: "Tipo de Transporte",
        mobility: "Mobilidade",
        electric: "Elétrica",
        soft: "Suave",
        micro: "Micro",
    },
    es: {
        reverseDirectionTooltip: "Direccion contraria",
        switchDirectionsTooltip: "",
        recenterTooltip: "Recentrar",
        options: "Opciones",
        nextDepartures: "Red",
        planRoute: "Planear Ruta",
        schedules: "Horarios",
        about: "Acerca de",
        searchPlaceholder: "Selecciona una parada",
        originStopPlaceHolder: "Salida",
        destinationStopPlaceHolder: "Llegada",
        plannedRoutes: "Opciones de Viaje",
        includes: "Incluye",
        changes: "transbordo(s)",
        walkAround: "Ir cerca de",
        estimatedWaitingTime: "Tiempo de espera entre",
        arrivalEstimatedAt: "Llegada estimada a las",
        and: "y",
        duration: "Duración",
        userLocationPlace: "Tu ubicación",
        road: "Carretera",
        rail: "Riel",
        bus: "Autobús",
        train: "Tren",
        boat: "Fluvial",
        plane: "Avión",
        fluvial: "Río",
        aerial: "Aéreo",
        cycling: "Carril bici",
        OptimiseArrivalTime: "Más rápido",
        OptimiseChanges: "Menos transferencias",
        OptimiseWalkTime: "Distancia minima a pie",
        OptimiseBicycleTime: "Menos trayecto en bicicleta",
        OptimiseScooterTime: "Menos trayecto en scooter",
        OptimiseTourism: "Más turístico",
        planRouteTypeOfTrip: "Tipo de Viaje",
        planRouteTimeFilterSelectNow: "Ahora",
        planRouteTranshipments: "Transbordos",
        planRouteTimeFilterSelectStartDate: "Seleccionar fecha de salida",
        planRouteTimeFilterSelectedStartDate: "Salida a las",
        planRouteTimeFilterSelectEndDate: "Seleccionar fecha de llegada",
        planRouteTimeFilterSelectedEndDate: "Llegada a las",
        optionsButtonTooltip: "Más opciones",
        switchDirectionsButtonTooltip: "Invertir sentido",
        userLocationButtonTooltip: "Usar tu ubicación",
        fares: "Tarifas",
        lineInfoStopsTitle: "Paradas",
        lineInfoMoreScheds: "más horarios",
        showStops: "Ver paradas",
        showSchedule: "Ver horario",
        showTrip: "Ver viaje",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horarios",
        noPassingsToShow: "Sin tiempos de paso para mostrar",
        noTripsToShow: "Sin viajes para mostrar",
        noInfoToShow: "Sin información para mostrar",
        notes: "Ver Notas",
        selectDate: "Selecciona una fecha",
        provider: "Operador",
        line: "Línea",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Vuelta",
        directionCircular: "Circular",
        daytype: "Tipo de Día",
        locale: "es",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        filters: "Filtros",
        close: "Cerrar",
        done: "Hecho",
        copyright: "Powered by OPT",
        allRightsReserved: "Desarrollado para Comunidad Intermunicipal del Algarve. Todos los derechos reservados",
        privacyPolicy: "Política de Privacidad",
        pageNotFound: "Página no encontrada",
        cofundedBy: "Cofinanciado por:",
        onFoot: "A pie",
        onBike: "En bicicleta",
        onScooter: "En scooter",
        from: "De",
        to: "a",
        transportTypeFilter: "Seleccionar modo de transporte",

        //PRIVACY POLICY
        rgpd: "RGPD",
        privacy_policy: "POLÍTICA DE PRIVACIDADE",
        privacy_policy_description: "O presente documento assume o compromisso da AMAL – Comunidade Intermunicipal do Algarve, para com todos os titulares de dados que trata na qualidade de Responsável pelo Tratamento de Dados Pessoais, do respeito pelas regras do Regulamento Geral de Proteção de Dados, Regulamento Europeu n.º 2016/679 (doravante RGPD) e da Lei de Execução Nacional (Lei n.º 59/2019, de 8 de Agosto) no tratamento de dados pessoais.",
        privacy_policy_bold_1: "A mudança de paradigma introduzida pelo Regulamento Geral de Proteção de Dados – RGPD",
        privacy_policy_paragraph_1: "Não obstante este compromisso e respeito pelas regras e obrigações no tratamento de dados pessoais já existisse antes de 25 de maio de 2018, com entrada em vigor do RGPD e, mais tarde, com a Lei de Execução Nacional que densificou esta norma na ordem jurídica nacional, estas regras assumiram uma relevância maior e a AMAL reforçou o seu compromisso com o respeito pela privacidade e a garantia do respeito pelo regime de tratamento de dados pessoais e pelas obrigações que impõe aos responsáveis pelo tratamento de dados pessoais.",
        privacy_policy_paragraph_2: "Com o objetivo de proteger e garantir o respeito pela privacidade do titular de dados pessoais, o novo regime consagra os seguintes direitos do titular de dados: direito de informação (art. 13.º e 14.º), direito de acesso (art. 15.º), direito de retificação (art. 16.º) direito ao apagamento dos dados “direito a ser esquecido” (art. 17.º), direito à limitação do tratamento (art. 18.º) direito de portabilidade de dados (art.20.º) direito de oposição (art. 21.º) e decisões individuais automatizadas, incluindo definição de perfis (art, 22.º). Pode o titular dos dados exercer estes direitos junto da AMAL, estando esta obrigada a dar-lhes o encaminhamento legal no prazo de 30 dias.",
        privacy_policy_paragraph_3: "Para efeitos deste regime, consideram-se dados pessoais as informações relativas a uma pessoa singular, identificada ou identificável, sendo que considera identificável uma pessoa que possa ser identificada direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular. Estão excluídos do âmbito de aplicação desta lei os dados das pessoas coletivas.",
        privacy_policy_paragraph_4: "Com a entrada em vigor do RGPD e da Lei de Execução Nacional, uma das mais importantes alterações reside na legitimidade que passa a ser necessária para proceder ao tratamento de dados e que, de acordo com o art. 6.º do RGPD assenta em, pelo menos, um dos seguintes fundamentos:",
        privacy_policy_bullet_1: "O titular dos dados tiver dado o seu consentimento para o tratamento dos seus dados pessoais para uma ou mais finalidades específicas;",
        privacy_policy_bullet_2: "O tratamento for necessário para a execução de um contrato no qual o titular dos dados é parte, ou para diligências pré-contratuais a pedido do titular dos dados;",
        privacy_policy_bullet_3: "O tratamento for necessário para o cumprimento de uma obrigação jurídica a que o responsável pelo tratamento esteja sujeito;",
        privacy_policy_bullet_4: "O tratamento for necessário para a defesa de interesses vitais do titular dos dados ou de outra pessoa singular;",
        privacy_policy_bullet_5: "O tratamento for necessário ao exercício de funções de interesse público ou ao exercício da autoridade pública de que está investido o responsável pelo tratamento;",
        privacy_policy_bullet_6: "O tratamento for necessário para efeito dos interesses legítimos prosseguidos pelo responsável pelo tratamento ou por terceiros, exceto se prevalecerem os interesses ou direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais, em especial se o titular for uma criança.",
        privacy_policy_paragraph_5: "A legitimidade da AMAL para proceder ao tratamento de dados pessoais assenta, essencialmente nas alíneas b), c), e) e f) do art. 6.º, n.º 1 do RGPD e, caso estas fontes de legitimidade não se verifiquem, será solicitado o consentimento ao titular.",
        privacy_policy_bold_2: "Recolha de dados pessoais (finalidades)",
        privacy_policy_paragraph_6: "Termos em que, a AMAL assegura que o tratamento dos seus Dados Pessoais é efetuado no âmbito da(s) finalidade(s) para os quais os mesmos foram recolhidos ou para finalidades compatíveis com o(s) propósito(s) inicial(is) para que foram recolhidos, nomeadamente através dos formulários disponíveis online – princípio da finalidade ou outros em suporte físico.",
        privacy_policy_paragraph_7: "Perante isso, assume a AMAL o compromisso de implementar uma cultura de minimização de Dados, em que apenas se recolhe, utiliza e conserva os Dados Pessoais estritamente necessários ao desenvolvimento da sua atividade e à satisfação dos interesses das/dos cidadãs/cidadãos – princípio da minimização.",
        privacy_policy_paragraph_8: "A AMAL respeita o dever de sigilo e confidencialidade, garantindo que não procederá à divulgação ou partilha dos seus Dados Pessoais para fins comerciais ou de publicidade – princípio da segurança e confidencialidade.",
        privacy_policy_paragraph_9: "A AMAL participa em vários projetos conjuntamente com outras entidades, tendo estes a sua própria Politica de Privacidade que respeita na dependência desta.",
        privacy_policy_bold_3: "Transmissão de dados pessoais a terceiros",
        privacy_policy_paragraph_10: "A AMAL somente poderá partilhar os seus Dados Pessoais com terceiros, quando essa partilha resulte do cumprimento de obrigações legais ou se verifique no exercício de funções de interesse público/autoridade pública. Caso não se verifique nenhuma destas situações será expressamente solicitado ao titular o respetivo consentimento nos termos e para os efeitos do art. 6.º, n.º 1, b) e art. 7.º do RGPD.",
        privacy_policy_bold_4: "Conservação dos dados pessoais",
        privacy_policy_paragraph_11: "",

        //ABOUT US
        title_who: "Quiénes somos",
        title_what: "Lo que hacemos",
        title_how: "Cómo lo hacemos",
        title_plan: "Planifica tu itinerario",
        title_info: "Información en tiempo real",

        text_who: "La CI-AMAL - Comunidade Intermunicipal do Algarve es una persona jurídica de derecho público, con naturaleza de asociación pública libremente constituida por los dieciséis municipios de la región del Algarve, de acuerdo con lo dispuesto en el Estatuto de las Entidades Intermunicipales, aprobado por la Ley n.º 75/2013, de 12/09, con sede en la calle General Humberto Delgado, nº 20, 8000 - 355 Faro, distrito, municipio y unión de freguesias de Faro, y contribuyente fiscal con el número 502 971 096. Es la Autoridad intermunicipal de Transportes de la región del Algarve (y municipal local a través de la asunción de las competencias delegadas por los municipios), en tanto que entidad pública con atribuciones y competencias en materia de organización, explotación, atribución, inversión, financiación, fiscalización, seguimiento y difusión del servicio público regular de transporte rodado y fluvial de pasajeros y en materia de contratación y determinación de obligaciones de servicio público y de tarifas.",
        text_what: "La movilidad es la facilidad de desplazamiento de las personas y bienes por el territorio, en el desarrollo de la actividad económica y social, realizada por medio de vehículos motorizados y no motorizados, a través de la infraestructura. Lo que permite ejercer el derecho de ir y venir cotidiano, de forma justa y digna, generando innumerables posibilidades y potenciando la conquista de nuevas oportunidades, independientemente del lugar donde se nace y donde se vive. La calidad de la movilidad se relaciona intrínsecamente con la articulación de los diferentes modos. La CI-AMAL está comprometida con ayudar a la región del Algarve en la mejora continua de la calidad de vida de las personas, superando limitaciones y obstáculos de la movilidad por el territorio. Ayudando en el desarrollo de un territorio más limpio, más seguro y mejor para vivir.",
        text_how: "Ayudamos a las personas a llegar a donde desean de la manera más sencilla y fácil, posibilitando la planificación de los viajes que combinan múltiples opciones de transporte, con información en tiempo real directamente accesible en la aplicación NESO. Trabajamos con las Autoridades municipales de Transportes y con el IMT, I.P., con los operadores de transporte público y empresas para superar los desafíos de la movilidad, para implementar soluciones de inteligencia artificial en la planificación, en la operación y en la evaluación del viaje. Las herramientas de la aplicación NESO mejoran la atracción del transporte público, mitigan los atascos, aumentan la eficiencia de todo el sistema, haciendo que más personas usen el transporte público, la micromovilidad, la movilidad suave y facilitan la movilidad eléctrica. Creamos tecnología para las personas en el Algarve, con un impacto muy positivo en las comunidades locales.",
        text_plan: "Encuentra la mejor ruta. Planifica tu viaje como prefieras. Nuestro planificador multimodal de viajes muestra las opciones de movilidad disponibles en la región, solo tienes que optar por la ruta a seguir, situándote en el punto de partida y seleccionando el punto de llegada y descubre las diferentes opciones de desplazamiento disponibles.",
        text_info: "La aplicación NESO es la primera y única integrada y dedicada a la región del Algarve. Aquí encontrarás todas las opciones de movilidad (autobús, tren, bicicletas, patinetes, etc.), en una sola aplicación: No pierdas tiempo esperando el autobús, el tren o el barco. ¡Entérate previamente a qué hora llegará tu modo de transporte a una parada o estación cerca de ti! ¡Comprueba exactamente dónde se encuentra tu autobús en el mapa y su hora de llegada! ¡Encuentra fácilmente las líneas de transporte público, visualiza carriles bici, parques de bicicletas, tiendas de alquiler de bicicletas y encuentra parques con bicicletas disponibles! ¡Pedalea por rutas actualizadas y descubre lo que el Algarve tiene para ofrecer! ¡Descubre toda la información útil de la aplicación NESO y obtén todos los datos necesarios para poder moverte con mayor libertad y facilidad por el territorio del Algarve!",
        //FILTER
        transports: "Tipo de Transporte",
        mobility: "Movilidad",
        electric: "Eléctrico",
        soft: "Suave",
        micro: "Micro",
    },
    fr: {
        recenterTooltip: "Recentrer",
        reverseDirectionTooltip: "Direction inverse",
        options: "Options",
        nextDepartures: "Prochaines départs",
        planRoute: "Planifier un itinéraire",
        schedules: "Horaires",
        about: "À propos",
        searchPlaceholder: "Sélectionnez un arrêt",
        originStopPlaceHolder: "Départ",
        destinationStopPlaceHolder: "Arrivée",
        plannedRoutes: "Options de voyage",
        includes: "Comprend",
        changes: "correspondances(s)",
        walkAround: "Flâner autour",
        estimatedWaitingTime: "Temps d'attente entre",
        arrivalEstimatedAt: "Arrivée estimée à",
        and: "et",
        duration: "Durée",
        userLocationPlace: "Votre position",
        bus: "Bus",
        train: "Former",
        boat: "Fluvial",
        road: "Route",
        rail: "Rail",
        fluvial: "Rivière",
        aerial: "Aérien",
        cycling: "Piste cyclable",
        plane: "Avion",
        OptimiseArrivalTime: "Le plus rapide",
        OptimiseChanges: "Moins de transferts",
        OptimiseWalkTime: "Moins de distance à pied",
        OptimiseBicycleTime: "Moins de trajet à vélo",
        OptimiseScooterTime: "Moins de trajet en scooter",
        OptimiseTourism: "Le plus touristique",
        planRouteTypeOfTrip: "Type de Voyage",
        planRouteTranshipments: "Transbordements",
        planRouteTimeFilterSelectNow: "Maintenant",
        planRouteTimeFilterSelectStartDate: "Sélectionner la date de départ",
        planRouteTimeFilterSelectedStartDate: "Départ à",
        planRouteTimeFilterSelectEndDate: "Sélectionner la date d'arrivée",
        planRouteTimeFilterSelectedEndDate: "Arrivée à",
        optionsButtonTooltip: "Plus d'options",
        switchDirectionsButtonTooltip: "Inverser le sens",
        userLocationButtonTooltip: "Utiliser votre position",
        fares: "Tarifs",
        lineInfoStopsTitle: "Arrêts",
        lineInfoMoreScheds: "plus d'horaires",
        showStops: "Voir les arrêts",
        showSchedule: "Voir l'horaire",
        showTrip: "Voir le voyage",
        showMap: "Voir la carte",
        moreSchedsTitle: "Horaires",
        noPassingsToShow: "Aucun temps de passage à afficher",
        noTripsToShow: "Aucun voyage à afficher",
        noInfoToShow: "Aucune information à afficher",
        notes: "Voir les notes",
        selectDate: "Sélectionnez une date",
        provider: "Opérateur",
        line: "Ligne",
        direction: "Sens",
        directionGo: "Aller",
        directionReturn: "Retour",
        directionCircular: "Circulaire",
        daytype: "Type de jour",
        locale: "fr",
        hoursFilter: "Filtrer par heures",
        initialHourFilter: "Heure initiale",
        finalHourFilter: "Heure finale",
        filter: "Filtrer",
        close: "Fermer",
        done: "Terminé",
        copyright: "Powered by OPT",
        allRightsReserved: "Développé pour la Communauté Intermunicipale de l'Algarve. Tous droits réservés",
        privacyPolicy: "Politique de confidentialité",
        pageNotFound: "Page introuvable",
        cofundedBy: "Cofinancé par :",
        onFoot: "À pied",
        onBike: "À vélo",
        onScooter: "En scooter",
        from: "De",
        to: "à",
        transportTypeFilter: "Sélectionnez le mode de transport",

        //PRIVACY POLICY
        rgpd: "RGPD",
        privacy_policy: "POLÍTICA DE PRIVACIDADE",
        privacy_policy_description: "O presente documento assume o compromisso da AMAL – Comunidade Intermunicipal do Algarve, para com todos os titulares de dados que trata na qualidade de Responsável pelo Tratamento de Dados Pessoais, do respeito pelas regras do Regulamento Geral de Proteção de Dados, Regulamento Europeu n.º 2016/679 (doravante RGPD) e da Lei de Execução Nacional (Lei n.º 59/2019, de 8 de Agosto) no tratamento de dados pessoais.",
        privacy_policy_bold_1: "A mudança de paradigma introduzida pelo Regulamento Geral de Proteção de Dados – RGPD",
        privacy_policy_paragraph_1: "Não obstante este compromisso e respeito pelas regras e obrigações no tratamento de dados pessoais já existisse antes de 25 de maio de 2018, com entrada em vigor do RGPD e, mais tarde, com a Lei de Execução Nacional que densificou esta norma na ordem jurídica nacional, estas regras assumiram uma relevância maior e a AMAL reforçou o seu compromisso com o respeito pela privacidade e a garantia do respeito pelo regime de tratamento de dados pessoais e pelas obrigações que impõe aos responsáveis pelo tratamento de dados pessoais.",
        privacy_policy_paragraph_2: "Com o objetivo de proteger e garantir o respeito pela privacidade do titular de dados pessoais, o novo regime consagra os seguintes direitos do titular de dados: direito de informação (art. 13.º e 14.º), direito de acesso (art. 15.º), direito de retificação (art. 16.º) direito ao apagamento dos dados “direito a ser esquecido” (art. 17.º), direito à limitação do tratamento (art. 18.º) direito de portabilidade de dados (art.20.º) direito de oposição (art. 21.º) e decisões individuais automatizadas, incluindo definição de perfis (art, 22.º). Pode o titular dos dados exercer estes direitos junto da AMAL, estando esta obrigada a dar-lhes o encaminhamento legal no prazo de 30 dias.",
        privacy_policy_paragraph_3: "Para efeitos deste regime, consideram-se dados pessoais as informações relativas a uma pessoa singular, identificada ou identificável, sendo que considera identificável uma pessoa que possa ser identificada direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular. Estão excluídos do âmbito de aplicação desta lei os dados das pessoas coletivas.",
        privacy_policy_paragraph_4: "Com a entrada em vigor do RGPD e da Lei de Execução Nacional, uma das mais importantes alterações reside na legitimidade que passa a ser necessária para proceder ao tratamento de dados e que, de acordo com o art. 6.º do RGPD assenta em, pelo menos, um dos seguintes fundamentos:",
        privacy_policy_bullet_1: "O titular dos dados tiver dado o seu consentimento para o tratamento dos seus dados pessoais para uma ou mais finalidades específicas;",
        privacy_policy_bullet_2: "O tratamento for necessário para a execução de um contrato no qual o titular dos dados é parte, ou para diligências pré-contratuais a pedido do titular dos dados;",
        privacy_policy_bullet_3: "O tratamento for necessário para o cumprimento de uma obrigação jurídica a que o responsável pelo tratamento esteja sujeito;",
        privacy_policy_bullet_4: "O tratamento for necessário para a defesa de interesses vitais do titular dos dados ou de outra pessoa singular;",
        privacy_policy_bullet_5: "O tratamento for necessário ao exercício de funções de interesse público ou ao exercício da autoridade pública de que está investido o responsável pelo tratamento;",
        privacy_policy_bullet_6: "O tratamento for necessário para efeito dos interesses legítimos prosseguidos pelo responsável pelo tratamento ou por terceiros, exceto se prevalecerem os interesses ou direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais, em especial se o titular for uma criança.",
        privacy_policy_paragraph_5: "A legitimidade da AMAL para proceder ao tratamento de dados pessoais assenta, essencialmente nas alíneas b), c), e) e f) do art. 6.º, n.º 1 do RGPD e, caso estas fontes de legitimidade não se verifiquem, será solicitado o consentimento ao titular.",
        privacy_policy_bold_2: "Recolha de dados pessoais (finalidades)",
        privacy_policy_paragraph_6: "Termos em que, a AMAL assegura que o tratamento dos seus Dados Pessoais é efetuado no âmbito da(s) finalidade(s) para os quais os mesmos foram recolhidos ou para finalidades compatíveis com o(s) propósito(s) inicial(is) para que foram recolhidos, nomeadamente através dos formulários disponíveis online – princípio da finalidade ou outros em suporte físico.",
        privacy_policy_paragraph_7: "Perante isso, assume a AMAL o compromisso de implementar uma cultura de minimização de Dados, em que apenas se recolhe, utiliza e conserva os Dados Pessoais estritamente necessários ao desenvolvimento da sua atividade e à satisfação dos interesses das/dos cidadãs/cidadãos – princípio da minimização.",
        privacy_policy_paragraph_8: "A AMAL respeita o dever de sigilo e confidencialidade, garantindo que não procederá à divulgação ou partilha dos seus Dados Pessoais para fins comerciais ou de publicidade – princípio da segurança e confidencialidade.",
        privacy_policy_paragraph_9: "A AMAL participa em vários projetos conjuntamente com outras entidades, tendo estes a sua própria Politica de Privacidade que respeita na dependência desta.",
        privacy_policy_bold_3: "Transmissão de dados pessoais a terceiros",
        privacy_policy_paragraph_10: "A AMAL somente poderá partilhar os seus Dados Pessoais com terceiros, quando essa partilha resulte do cumprimento de obrigações legais ou se verifique no exercício de funções de interesse público/autoridade pública. Caso não se verifique nenhuma destas situações será expressamente solicitado ao titular o respetivo consentimento nos termos e para os efeitos do art. 6.º, n.º 1, b) e art. 7.º do RGPD.",
        privacy_policy_bold_4: "Conservação dos dados pessoais",
        privacy_policy_paragraph_11: "",

        //ABOUT US
        title_who: "Qui sommes-nous",
        title_what: "Que faisons-nous",
        title_how: "Comment le faisons-nous",
        title_plan: "Planifier votre itinéraire",
        title_info: "Informations en temps réel",

        text_who: "La CI-AMAL - Comunidade Intermunicipal do Algarve est une personne morale de droit public, de nature d'association publique librement constituée par les seize communes de la région de l'Algarve, conformément aux dispositions du Statut des entités intermunicipales, approuvé par la loi n° 75/2013, du 12/09, dont le siège est situé au 20 rue Général Humberto Delgado, 8000-355 Faro, district, commune et union de paroisses de Faro, et numéro de contribuable 502 971 096. Il est l'autorité intermunicipale des transports de la région de l'Algarve (et locale par délégation des communes), en tant qu'entité publique ayant des attributions et des compétences en matière d'organisation, d'exploitation, d'attribution, d'investissement, de financement, de contrôle, de surveillance et de diffusion du service public régulier de transport routier et fluvial de passagers et en matière de contractualisation et de détermination d'obligations de service public et de tarifs.",
        text_what: "La mobilité est la facilité de déplacement des personnes et des biens sur le territoire, dans le développement de l'activité économique et sociale, réalisée par des véhicules motorisés et non motorisés, via l'infrastructure. Ce qui permet d'exercer le droit de se déplacer quotidiennement de manière juste et digne, générant de nombreuses possibilités et favorisant la conquête de nouvelles opportunités, indépendamment du lieu de naissance ou de résidence. La qualité de la mobilité est intrinsèquement liée à l'articulation des différents modes. CI-AMAL s'engage à aider la région de l'Algarve à améliorer continuellement la qualité de vie des personnes, en surmontant les limitations et les obstacles à la mobilité sur le territoire. En aidant au développement d'un territoire plus propre, plus sûr et meilleur pour vivre.",
        text_how: "Nous aidons les gens à se rendre où ils veulent de la manière la plus simple et la plus facile, en permettant la planification des voyages qui combinent plusieurs options de transport, avec des informations en temps réel directement accessibles dans l'application NESO. Nous travaillons avec les autorités locales des transports et l'IMT, IP, les opérateurs de transport public et les entreprises pour relever les défis de la mobilité, mettre en œuvre des solutions d'intelligence artificielle dans la planification, l'exploitation et l'évaluation des voyages. Les outils de l'application NESO améliorent l'attractivité du transport public, atténuent les congestions, augmentent l'efficacité de l'ensemble du système, encouragent plus de personnes à utiliser les transports publics, la micromobilité, la mobilité douce et facilitent la mobilité électrique. Nous créons des technologies pour les personnes de l'Algarve, avec un impact très positif sur les communautés locales.",
        text_plan: "Trouvez le meilleur itinéraire. Planifiez votre voyage comme vous le souhaitez. Notre planificateur de voyages multimodal affiche les options de mobilité disponibles dans la région. Vous n'avez qu'à choisir l'itinéraire, vous situer au point de départ et sélectionner le point d'arrivée et découvrir les différentes options de déplacement disponibles.",
        text_info: "L'application NESO est la première et la seule application intégrée et dédiée à la région de l'Algarve. Vous y trouverez toutes les options de mobilité (bus, train, vélos, trottinettes, etc.) dans une seule application : ne perdez pas de temps à attendre le bus, le train ou le bateau. Sachez à l'avance à quelle heure votre mode de transport arrivera à une gare ou à une station à proximité. Vérifiez exactement où se trouve votre bus sur la carte et son heure d'arrivée ! Trouvez facilement les lignes de transport public, visualisez les pistes cyclables, les parcs à vélos, les magasins de location de vélos et trouvez des parcs avec des vélos disponibles. Faites du vélo sur des itinéraires mis à jour et découvrez ce que l'Algarve a à offrir. Découvrez toutes les informations utiles de l'application NESO et obtenez toutes les données nécessaires pour vous déplacer avec plus de liberté et de facilité sur le territoire de l'Algarve !",

        //FILTER
        filters: "Filtres",
        transports: "Type de Transport",
        mobility: "Mobilité",
        electric: "Électrique",
        soft: "Soft",
        micro: "Micro",
    }
});

export const changeLanguage = (languageKey) => {
    strings.setLanguage(languageKey);
}
