import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { SearchCommand } from './commands/SearchCommand';
import { SearchBarResults } from './SearchBarResults';

export class SearchBar extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.stateKey = "searchBar";
        this.timeoutId = undefined;
        this.state = {
            commands: {
                stops: new SearchCommand()
            },
            searchText: "",
            showResults: false,
            results: [],
            googleResults: []
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const { caller } = this.props;
        const state = getState(`${caller || "default"}-${this.stateKey}`);

        if (undefined !== state) {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        const { caller } = this.props;
        setState(`${caller || "default"}-${this.stateKey}`, this.state);
    }

    updateSearchText(e) {
        this.setState({
            searchText: e.target.value
        }, () => {
            if (this.timeoutId !== undefined) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(() => {
                this.performSearch();
                this.timeoutId = undefined;
            }, 500);
        });
    }

    updateSearchTextWithoutSearching(text) {
        this.setState({
            searchText: text
        }, () => {
            this.performSearch(false);
        });
    }

    clearSearchText() {
        const { onSearchClear } = this.props;

        onSearchClear();

        this.setState({
            searchText: ""
        }, () => {
            this.performSearch();
        });
    }

    handleResultClick(stop, recenter) {
        const { onSelect } = this.props;
        onSelect(stop, recenter);
        this.setState({
            searchText: stop.name,
            showResults: false
        });
    }

    handleFocus() {
        this.setState({
            showResults: true
        });
    }

    searchSuccessCallback(result, showResults = true) {
        this.setState({
            showResults: showResults,
            results: result
        });
    }

    performSearch(showResults = true) {
        const { searchText, commands } = this.state;

        if (3 > searchText.length) {
            this.setState({ results: [], googleResults: [] });
        } else {
            this.performGoogleSearch();
            commands.stops.performSearch(searchText, (r) => this.searchSuccessCallback(r, showResults));
        }
    }

    performGoogleSearch() {
        const { searchText } = this.state;
        const google = window.google;
        let request = {
            query: searchText,
            fields: ['name', 'formatted_address', 'geometry', 'types'],
            locationBias: { radius: 55000, center: { lat: 41.1483136, lng: -8.6130035 } }
        };
        let service = new google.maps.places.PlacesService(document.createElement('div'));

        let results = [];

        service.findPlaceFromQuery(request, (googleResults, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                for (var i = 0; i < googleResults.length; i++) {
                    if (i >= 3) break;
                    results.push({
                        code: "",
                        coordX: googleResults[i].geometry.location.lat(),
                        coordY: googleResults[i].geometry.location.lng(),
                        id: 0,
                        name: googleResults[i].types.find(t => t === 'street_address') !== undefined ? googleResults[i].formatted_address : googleResults[i].name,
                        provider: "",
                        regions: [],
                        restriction: 0,
                        type: 0
                    });
                }

                this.setState({
                    googleResults: results,
                    displayResults: results.length > 0 ? true : false
                });
            }
        });
    }

    renderResults() {
        const { results, googleResults, showResults } = this.state;
        const { resultsClassName } = this.props;

        let joinedResults = googleResults.concat(results);
        
        if (0 === joinedResults.length || !showResults) {
            return null;
        }

        return <SearchBarResults className={resultsClassName} data={joinedResults} onSelect={(stop, recenter) => this.handleResultClick(stop, recenter)} />;
    }

    render() {
        const { searchText } = this.state;
        const { className, placeholder, isRequired, caller } = this.props;
        return (
            <div className={className}>
                <input id={`searchbar-${caller}`} className="search-bar" type="text" placeholder={placeholder} value={searchText} onChange={(e) => this.updateSearchText(e)} onFocus={() => this.handleFocus()} autoComplete="off" required={isRequired} />
                <button type="button" className="search-bar-cancel-icon" onClick={() => this.clearSearchText()}>
                    <div className="icon-error" />
                </button>
                {this.renderResults()}
            </div>
        );
    }
}