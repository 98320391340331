import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';

const planRouteApiRoutes = {
    defaultRoute: "planroute/"
};

export class PlanRouteAPI {
    static calculateRoute(request) {
        const url = `${planRouteApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.POST,
            body: JSON.stringify(request),
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}