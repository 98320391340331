import React from 'react';
import { useRef, useMemo } from 'react';
import { Marker } from 'react-leaflet';

export function DraggableMarker(args) {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    args.handleDrag(marker.getLatLng());
                }
            },
        }),
        [args]
    );

    return (
        <Marker key={args.markerKey} icon={args.icon} draggable={true} eventHandlers={eventHandlers} position={args.position} ref={markerRef}>
            {args.tooltip}
        </Marker>
    )
}
