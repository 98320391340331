import { PlanRouteAPI } from '../../api/PlanRouteAPI';

export class PlanRouteCommand {
    calculateRoute(request, successCallback, errorCallback) {
        const apiResult = PlanRouteAPI.calculateRoute(request);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
