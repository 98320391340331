import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { isSafari } from 'react-device-detect';
import { Layout } from './components/Layout';
import { NextDepartures } from './components/nextdepartures/NextDepartures';
import { PlanRoute } from './components/planroute/PlanRoute';
import { Schedules } from './components/schedules/Schedules';
import { About } from './components/about/About';
import { Privacy } from './components/privacy/Privacy';
import { NotFound } from './components/NotFound';
import { LogoCommand } from '../src/components/commands/LogoCommand';
import { changeLanguage } from "./resources/strings.js";

import './custom.css';

export default class App extends Component {
    static childContextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        updateLanguage: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func,
        getUserLocation: PropTypes.func
    };

    static displayName = App.name;

    constructor(props) {
        super(props);

        this.nextDeparturesRef = createRef();

        this.state = {
            states: {
                nextDepartures: undefined,
                lineInfo: undefined,
                stopPassingInfo: undefined,
                searchBar: undefined,
                planRoute: undefined,
                schedules: undefined,
            },
            commands: {
                logos: new LogoCommand()
            },
            mapCenter: [window.defaultCoordinates.lat, window.defaultCoordinates.lng],
            userLocation: [],
            useUserLocation: false,
            waitForGeolocationCheck: true,
            logos: {}
        };
    }

    componentDidMount() {
        //Check is geolocation is available
        if ("geolocation" in navigator) {
            if (!isSafari) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    //Used to make app run while user has not yet accepted/denied location access
                    if (result.state === 'prompt') {
                        this.setState({
                            waitForGeolocationCheck: false
                        }, () => this.initializeLocation())
                    } else {
                        this.initializeLocation();
                    }
                });
            } else {
                this.initializeLocation();
            }
        }

        this.getLogos();
    }

    //USER LOCATION / MAP CENTER / RECENTER
    initializeLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.setState({
                    mapCenter: [position.coords.latitude, position.coords.longitude],
                    userLocation: [position.coords.latitude, position.coords.longitude],
                    useUserLocation: true,
                    waitForGeolocationCheck: false
                }, () => {
                    //Used to make sure when user accepts prompt for location we get the nearstops with the new coords
                    if (null !== this.nextDeparturesRef.current) {
                        this.nextDeparturesRef.current.getNearStops();
                    }
                });
            },
            (error) => {
                this.setState({
                    waitForGeolocationCheck: false
                });
            }
        );
    }

    getMapCenter() {
        const { mapCenter } = this.state;
        return mapCenter;
    }

    setMapCenter(center, callback) {
        const { mapCenter } = this.state;

        if (mapCenter[0] === center[0] && mapCenter[1] === center[1]) {
            if ("function" === typeof callback) {
                callback();
            }

            return;
        }

        this.setState({
            mapCenter: center
        }, () => {
            if ("function" === typeof callback) {
                callback();
            }
        });
    }

    getUserLocation() {
        const { userLocation } = this.state;

        return userLocation;
    }

    recenter(getNearStopsCallback) {
        const { useUserLocation } = this.state;

        if (useUserLocation) {
            this.setMapCenter(this.state.userLocation, getNearStopsCallback);
        } else {
            this.setMapCenter([window.defaultCoordinates.lat, window.defaultCoordinates.lng], getNearStopsCallback);
        }
    }
    //--------------------

    //LOGOS
    getLogos() {
        const { commands } = this.state;
        commands.logos.getLogos((r) => this.getLogosSuccessCallback(r));
    }

    getLogosSuccessCallback(result) {
        this.setState({
            logos: result
        });
    }
    //----------------

    //Update Language
    updateLanguage(languageKey) {
        changeLanguage(languageKey);
        this.setState({});
    }

    //----------------


    getChildContext() {
        return {
            getState: (a) => this.getComponentState(a),
            setState: (a, b) => this.setComponentState(a, b),
            getLogo: (provider) => this.getLogo(provider),
            getMapCenter: () => this.getMapCenter(),
            setMapCenter: (center, callback) => this.setMapCenter(center, callback),
            updateLanguage: (languageKey) => this.updateLanguage(languageKey),
            recenter: (callback) => this.recenter(callback),
            getUserLocation: () => this.getUserLocation()
        };
    }

    getComponentState(which) {
        const { states } = this.state;
        return states[which];
    }

    setComponentState(which, newState) {
        const { states } = this.state;

        states[which] = newState;

        this.setState({
            states: states
        });
    }

    getLogo(provider) {
        const { logos } = this.state;
        if (undefined !== logos[provider]) {
            return `http://www.neso.pt/content/logos/${logos[provider]}`;
        }

        return null;
    }

    render() {
        const { waitForGeolocationCheck } = this.state;

        if (waitForGeolocationCheck) {
            return null;
        }

        return (
            <Layout>
                <Switch>
                    <Redirect exact from='/' to='/nextdepartures' />
                    <Route path='/nextdepartures' render={() => <NextDepartures ref={this.nextDeparturesRef} />} />
                    <Route path='/plan-route' component={PlanRoute} />
                    <Route path='/schedules' component={Schedules} />
                    <Route path='/about' component={About} />
                    <Route path='/privacy' component={Privacy} />
                    <Route path='*' component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}
