import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Container, Row, Col, Button, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import { strings } from '../../resources/strings.js';
import { ScheduleCommand } from '../commands/ScheduleCommand.js';
import { LineInfoMap } from './LineInfoMap';
import DatePicker from "../libs/react-modern-calendar-datepicker/src/DatePicker";
import "../libs/react-modern-calendar-datepicker/src/DatePicker.css";


export class MoreSchedules extends Component {
    static contextTypes = {
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            commands: {
                schedules: new ScheduleCommand()
            },
            passings: [],
            hoursArray: [],
            initalHourIndex: undefined,
            finalHourIndex: undefined,
            selectedDate: null,
            showTimeFilterPanel: false,
            isLoadingPassings: true,
            displayScheduleInfo: true,
            displayMap: true
        }
    }

    componentDidMount() {
        //Set function to handle when window is resized
        window.onresize = () => this.resize();

        this.getSchedule(new Date());
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    resize() {
        if (window.innerWidth > 767) {
            this.setState({
                displayScheduleInfo: true,
                displayMap: true
            });
        } else {
            document.getElementById("more-schedules-show-stops-button").click();
        }
    }

    //----------------

    getSchedule(date) {
        const { passing } = this.props;
        const { commands } = this.state;
        this.setState({
            isLoadingPassings: true
        });
        commands.schedules.getScheduleByLineAndDate(passing.lineId, passing.stopId, passing.direction, date.toISOString(), (r) => this.getScheduleSuccessCallback(r));
    }

    getScheduleSuccessCallback(result) {
        this.setState({
            passings: result,
            isLoadingPassings: false
        }, () => this.populateHoursArray());
    }

    //Array to use in the time filter
    populateHoursArray() {
        const { passings } = this.state;
        let hours = [];

        if (passings.length > 0) {
            let initialTimeStamp = passings[0].timeStamp;
            let finalTimeStamp = passings[passings.length - 1].timeStamp;

            let initialHour = Math.floor(initialTimeStamp / 60);

            //Add an extra hour to the final hour. Example: If the last trip is at 01:01 AM we add the option to see trips until 2AM
            //Adding 60 to the timestamp is the safest way to do it, given that the division always returns an Integer.
            let finalHour = Math.floor((finalTimeStamp + 60) / 60);

            for (let i = initialHour; i <= finalHour; i++) {
                hours.push(i);
            }

            this.setState({
                hoursArray: hours,
                initialHourIndex: 0,
                finalHourIndex: hours.length - 1
            });
        }
    }

    //--------------------

    //HELPER FUNCTIONS

    handleSelectedDate(date) {
        const newDateObject = new Date(date.year, date.month - 1, date.day);
        this.setState({
            selectedDate: date
        });
        this.getSchedule(newDateObject);
    }

    handleShowTimeFilter(show) {
        this.setState({
            showTimeFilterPanel: show
        });
    }

    handleInitialHourSelection(index) {
        this.setState({
            initialHourIndex: index
        }, () => this.filterPassings());
    }

    handleFinalHourSelection(index) {
        this.setState({
            finalHourIndex: index
        }, () => this.filterPassings());
    }

    formatHour(hour) {
        if (hour < 10) {
            return `0${hour}h`;
        }
        else if (hour >= 24) {
            if (hour - 24 < 10) {
                return `0${hour - 24}h`;
            } else {
                return `${hour - 24}h`;
            }
        } else {
            return `${hour}h`;
        }
    }

    filterPassings() {
        const { initialHourIndex, finalHourIndex, passings } = this.state;

        if (undefined === initialHourIndex || undefined === finalHourIndex) {
            return passings;
        }

        const { hoursArray } = this.state;

        let initialTimeStamp = hoursArray[initialHourIndex] * 60;
        let finalTimeStamp = hoursArray[finalHourIndex] * 60;

        return passings.filter(p => p.timeStamp >= initialTimeStamp && p.timeStamp <= finalTimeStamp);
    }

    displayScheduleInfo() {
        this.setState({
            displayScheduleInfo: true,
            displayMap: false
        });
    }

    displayMap() {
        this.setState({
            displayScheduleInfo: false,
            displayMap: true
        });
    }

    //--------------

    renderHeader(passing, trip) {
        const { onReturn } = this.props;
        const { passings, displayScheduleInfo, displayMap } = this.state;

        if (undefined === passing || 0 === passings.length) {
            return (
                <div className="more-schedules-header">
                    <div>
                        <Button className="more-schedules-back-button" variant="light" onClick={() => onReturn()}>
                            <div className={"icon-redo"} />
                        </Button>
                    </div>
                    <div className="more-schedules-panel-header text-align-center">
                        <span>{strings.noInfoToShow}</span>
                    </div>
                </div>
            );
        }

        const { provider } = this.props;

        return (
            <div className="more-schedules-header">
                <Button className="more-schedules-back-button" variant="light" onClick={() => onReturn()}>
                    <div className={"icon-redo"} />
                </Button>

                <div className="more-schedules-small-screen-tabs">
                    <Button id="more-schedules-show-stops-button" className={`more-schedules-small-screen-tabs-link-button ${displayScheduleInfo ? "orange-text" : ""}`} variant="link" onClick={() => this.displayScheduleInfo()}>
                        {strings.showSchedule}
                    </Button>
                    <span className="orange-text">|</span>
                    <Button id="more-schedules-show-map-button" className={`more-schedules-small-screen-tabs-link-button ${displayMap ? "orange-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                        {strings.showMap}
                    </Button>
                </div>


                <div className="more-schedules-panel-header">
                    <div className="more-schedules-panel-header-provider-info">
                        {this.renderProviderLogo(provider)}
                        <div className="more-schedules-panel-header-trip-info">
                            <div><b>{passing.lineCode}</b> {passing.name}</div>
                            <div>{`${trip.passings[0].name} > ${trip.passings[trip.passings.length - 1].name}`}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="40px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderFilters() {
        const { selectedDate } = this.state;

        return (
            <div className="more-schedules-filters">
                <div onClick={() => this.handleShowTimeFilter(false)}>
                    <DatePicker
                        value={selectedDate}
                        locale={strings.locale}
                        onChange={(e) => this.handleSelectedDate(e)}
                        inputPlaceholder={strings.selectDate}
                        colorPrimary="#3167a4"
                        inputClassName="more-schedules-date-picker-input"
                        shouldHighlightWeekends
                    />
                </div>

                <div className="more-schedules-time-filter">
                    <Button className="more-schedules-time-filter-open-button" variant="primary" onClick={() => this.handleShowTimeFilter(true)}>
                        {strings.hoursFilter}
                    </Button>
                    {this.renderTimePickers()}
                </div>
            </div>
        );
    }

    renderTimePickers() {
        const { showTimeFilterPanel, hoursArray, initialHourIndex, finalHourIndex } = this.state;
        return (
            showTimeFilterPanel ?
                <div className="more-schedules-time-filter-panel">
                    <div className="more-schedules-time-filter-panel-time-pickers">
                        <DropdownButton variant="secondary" key="startHour" title={this.formatHour(hoursArray[initialHourIndex])} onSelect={(e) => this.handleInitialHourSelection(e)}>
                            {
                                hoursArray.map((h, index) =>
                                    <Dropdown.Item key={`initialHour-${index}`} eventKey={index}>{this.formatHour(h)}</Dropdown.Item>
                                )
                            }
                        </DropdownButton>
                        <DropdownButton variant="secondary" key="finalHour" title={this.formatHour(hoursArray[finalHourIndex])} onSelect={(e) => this.handleFinalHourSelection(e)}>
                            {
                                hoursArray.map((h, index) =>
                                    <Dropdown.Item key={`finalHour-${index}`} eventKey={index}>{this.formatHour(h)}</Dropdown.Item>
                                )
                            }
                        </DropdownButton>
                    </div>

                    <div className="more-schedules-time-filter-panel-buttons">
                        <Button variant="link" onClick={() => this.handleShowTimeFilter(false)}>
                            {strings.close}
                        </Button>
                    </div>
                </div>
                :
                null
        );
    }

    renderPassings(passings) {
        if (undefined === passings || 0 === passings.length) {
            return null;
        }

        let filteredPassings = this.filterPassings();

        return (
            <div className="more-schedules-panel">
                <span className="more-schedules-title">{strings.moreSchedsTitle}</span>
                <Container fluid className="more-schedules-passings-list">
                    {
                        filteredPassings.map((passing, index) =>

                            <Row key={`more-schedules-passing-${index}`}>
                                <Col xs={12}>
                                    {passing.formattedTimeStamp}
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
        );
    }

    renderInfo(passing, passings, trip) {
        if (undefined === passing || 0 === passings.length) {
            return this.renderHeader(passing, trip);
        }

        return (
            <div className="more-schedules-panel">
                {this.renderFilters()}
                {this.renderPassings(passings)}
            </div>
        );
    }

    renderScreens(trip, passing) {
        const { passings, isLoadingPassings, displayScheduleInfo, displayMap } = this.state;

        if (displayScheduleInfo && displayMap) {
            return (
                <Row>
                    <Col sm={5} className="more-schedules-container-passings-col">
                        {this.renderHeader(passing, trip)}
                        {
                            isLoadingPassings ?
                                <div className="more-schedules-panel-header text-align-center">
                                    <Spinner animation="border" role="status" />
                                </div>
                                :
                                this.renderInfo(passing, passings, trip)
                        }
                    </Col>
                    <Col sm={7} className="more-schedules-container-map-col">
                        <LineInfoMap trip={trip} passing={passing} makeTooltipsPermanent={false} />
                    </Col>
                </Row>
            );
        } else {
            if (displayScheduleInfo) {
                return (
                    <Row>
                        <Col sm={12} className="more-schedules-container-passings-col">
                            {this.renderHeader(passing, trip)}
                            {
                                isLoadingPassings ?
                                    <div className="more-schedules-panel-header text-align-center">
                                        <Spinner animation="border" role="status" />
                                    </div>
                                    :
                                    this.renderInfo(passing, passings, trip)
                            }
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="more-schedules-container-map-col">
                            {this.renderHeader(passing, trip)}
                            <LineInfoMap trip={trip} passing={passing} makeTooltipsPermanent={false} />
                        </Col>
                    </Row>
                );
            }
        }
    }


    render() {
        const { trip, passing } = this.props;

        return (
            <Container fluid className="more-schedules-container">
                {this.renderScreens(trip, passing)}
            </Container>
        );
    }
}