import { PoiAPI } from '../../api/PoiAPI';

export class PoiCommand {
    getPois(successCallback, errorCallback) {
        const apiResult = PoiAPI.getPois();
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getNearPois(latitude, longitude, radius, successCallback, errorCallback) {
        const apiResult = PoiAPI.getNearPois(latitude, longitude, radius);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
