import React, { Component } from 'react';
import { FilterMenu } from './FilterMenu';

export class FilterButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    onChangeOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const {
            className,
            callback,
            title,
            requestPreferences,
            request,
            requestChangePreferences,
            availableTransportTypes,
            requestTransportTypes,
            handlePreferencesChange,
            handleChangePreferencesChange,
            handleTransportTypesChange
        } = this.props;

        const { isOpen } = this.state;

        return (
            <div className={className}>
                <button className="filter-button" title={title} onClick={() => this.onChangeOpen()}>
                    <div className="icon-definitions" />
                </button>
                {isOpen ?
                    <FilterMenu
                        callback={callback}
                        requestPreferences={requestPreferences}
                        request={request}
                        requestChangePreferences={requestChangePreferences}
                        availableTransportTypes={availableTransportTypes}
                        requestTransportTypes={requestTransportTypes}
                        handlePreferencesChange={(e) => handlePreferencesChange(e)}
                        handleChangePreferencesChange={(e) => handleChangePreferencesChange(e)}
                        handleTransportTypesChange={(e) => handleTransportTypesChange(e)}
                    />
                    : null}
            </div>
        );
    }
}