import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { strings } from '../resources/strings.js';

export class Footer extends Component {

    render() {
        return (
            <div>
                <Container fluid>
                    <div className="footer">
                        <span>
                            <a href="https://www.opt.pt/" target="_blank" rel="noopener noreferrer">{strings.copyright}</a>. {strings.allRightsReserved}. <a href="https://amal.pt/termos-de-utilizacao-e-politica-de-privacidade">{strings.privacyPolicy}</a>
                            <div className="cofunding">
                                <span>{strings.cofundedBy}</span>
                                <img src="icons/POAlgarve_PT2020_FSE_Bom.png" alt="Cofinanciamento" height={30} />
                            </div>
                        </span>                        
                    </div>
                </Container>
            </div>
        )
    }
}