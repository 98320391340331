import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { MapContainer, TileLayer, useMap, useMapEvents, Marker, Tooltip, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { SearchBar } from '../SearchBar';
import { StopCommand } from '../commands/StopCommand';
import { LaneCommand } from '../commands/LaneCommand';
import { PoiCommand } from '../commands/PoiCommand';
import {
    stopIcon, selectedStopIcon, busStopIcon, selectedBusStopIcon, trainStopIcon, selectedTrainStopIcon, boatStopIcon, selectedBoatStopIcon,
    planeStopIcon, selectedPlaneStopIcon, uncategorizedPoiIcon, selectedUncategorizedPoiIcon, ticketOfficePoiIcon, selectedTicketOfficePoiIcon,
    bikePoiIcon, selectedBikePoiIcon, bikeParkingPoiIcon, selectedBikeParkingPoiIcon, boatPoiIcon, selectedBoatPoiIcon, scooterPoiIcon, selectedScooterPoiIcon,
    eVChargerIcon, selectedEVChargerIcon
} from '../leaflet/StopIcons';
import { Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { RecenterButton } from '../RecenterButton';
import { FilterButton } from './filter/FilterButton';

export class StopPassingInfo extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.stateKey = "stopPassingInfo";
        this.state = {
            commands: {
                stops: new StopCommand(),
                lanes: new LaneCommand(),
                pois: new PoiCommand()
            },
            filterOptions: {
                transports: {
                    road: true,
                    rail: true,
                    fluvial: true,
                    aerial: true,
                },
            mobility: {
                electric: true,
                soft: true,
                micro: true,
                cycling: true
            }
            },
            nearPhysicalStops: [],
            filteredPhysicalStops: [],
            nearPois: [],
            filteredPois: [],
            nearLanes: [],
            filteredLanes: [],
            lanePolylines: [],
            mapRadius: 1500,
            selectedPhysicalStop: undefined,
            selectedPoi: undefined,
            selectedLane: undefined,
            isLoadingPassings: false,
            isToRecenter: true
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getNearInfo();
        } else {
            state.isToRecenter = true;
            this.setState(state, () => this.getPhysicalStopTimes());
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        setState(this.stateKey, this.state);
    }

    setMapCenter(center, radius) {
        const { setMapCenter } = this.context;

        setMapCenter([center.lat, center.lng], () => {
            //Set the new map radius and get data
            this.setState({
                mapRadius: radius
            }, () => {
                this.getNearInfo();
            });
        });
    }

    getNearInfo(matchStopToPhysicalStopCallback) {
        this.getNearPhysicalStops(matchStopToPhysicalStopCallback);
        this.getNearPois();
        this.getNearLanes();
    }

    //NEAR STOPS
    getNearPhysicalStops(matchStopToPhysicalStopCallback) {
        const { commands, mapRadius } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.stops.getNearPhysicalStops(mapCenter[0], mapCenter[1], parseInt(mapRadius, 10), (r) => this.getNearPhysicalStopsSuccessCallback(r, matchStopToPhysicalStopCallback))
    }

    getNearPhysicalStopsSuccessCallback(result, matchStopToPhysicalStopCallback) {
        this.setState({
            nearPhysicalStops: result
        }, () => {
            if ("function" === typeof matchStopToPhysicalStopCallback) {
                matchStopToPhysicalStopCallback();
            }
            this.filterStops();
        });
    }

    filterStops(){
        const { nearPhysicalStops, filterOptions } = this.state;
        let { filteredPhysicalStops } = this.state;
        
        const transportFilter = filterOptions.transports;

        filteredPhysicalStops = nearPhysicalStops.filter((i) => 
            (i.places[0].transportType === 0 && transportFilter.road) ||
            (i.places[0].transportType === 1 && transportFilter.rail) ||
            (i.places[0].transportType === 2 && transportFilter.fluvial) ||
            (i.places[0].transportType === 3 && transportFilter.aerial)
        );

        this.setState({
            filteredPhysicalStops: filteredPhysicalStops
        })
    }
    
    filterCallback(newOptions, stops){
      this.setState({
        filterOptions: newOptions
      }, ()=>{
        if(stops){
          this.filterStops();
        }else{
          this.filterPois();
          this.filterLanes();
        }
      })
    }

    matchStopToPhysicalStop(stop) {
        //if stop, match to physical stop
        if (1 === stop.type) {
            let correspondingPhysicalStop = undefined;
            if (stop.cluster === null) {
                correspondingPhysicalStop = this.state.nearPhysicalStops.find(ps => ps.clusterId === stop.id && ps.clusterName === stop.name);
            } else {
                correspondingPhysicalStop = this.state.nearPhysicalStops.find(ps => ps.clusterId === stop.cluster.id);
            }
            this.handlePhysicalStopSelection(correspondingPhysicalStop);
        }
    }
    //----------

    //NEAR POIS
    getNearPois() {
        const { commands, mapRadius } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.pois.getNearPois(mapCenter[0], mapCenter[1], parseInt(mapRadius, 10), (r) => this.getNearPoisSuccessCallback(r))
    }

    getNearPoisSuccessCallback(result) {
        this.setState({
            nearPois: result
        }, () => this.filterPois());
    }

    filterPois(){
        const { nearPois, filterOptions } = this.state;
        let { filteredPois } = this.state;

        const mobilityFilter = filterOptions.mobility;

        filteredPois = nearPois.filter((i) => 
            (i.type === 10 && mobilityFilter.electric) ||
            ((i.type === 6 || i.type === 9 || i.type === 5) && mobilityFilter.soft) ||
            (i.type === 7 && mobilityFilter.micro)
        )

        this.setState({
            filteredPois: filteredPois
        })
    }
    //----------

    //NEAR LANES
    getNearLanes() {
        const { commands, mapRadius } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.lanes.getNearLanes(mapCenter[0], mapCenter[1], parseInt(mapRadius, 10), (r) => this.getNearLanesSuccessCallback(r))
    }

    getNearLanesSuccessCallback(result) {
        this.setState({
            nearLanes: result
        }, ()=>{
            this.filterLanes();
        });
    }

    filterLanes(){
        const { nearLanes, filterOptions } = this.state;
        let { filteredLanes } = this.state;

        if(filterOptions.mobility.cycling){
            filteredLanes = nearLanes;
        }else{
            filteredLanes = [];
        }

        this.setState({
            filteredLanes: filteredLanes
        })
    }

    //----------

    //PHYSICAL STOP TIMES
    getPhysicalStopTimes() {
        this.setState({
            isToRecenter: false,
            isLoadingPassings: true
        });

        const { selectedPhysicalStop, commands } = this.state;
        if (undefined !== selectedPhysicalStop) {
            commands.stops.getPhysicalStopTimes(selectedPhysicalStop, (r) => this.getPhysicalStopTimesSuccessCallback(r));
        }
    }

    getPhysicalStopTimesSuccessCallback(result) {
        const { selectedPhysicalStop } = this.state;
        selectedPhysicalStop.passings = result;

        this.setState({
            selectedPhysicalStop: selectedPhysicalStop,
            isLoadingPassings: false
        });
    }
    //----------

    //Helper Functions
    handleSearchResultSelection(place, recenter) {
        if (place.type === 0 || place.type === 1) {
            this.handleStopSearchSelection(place, recenter);
        } else {
            this.handlePoiSelection(place, recenter);
        }
    }

    handleStopSearchSelection(stop, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([stop.coordX, stop.coordY], () => this.getNearInfo(() => this.matchStopToPhysicalStop(stop)));
        }

        this.setState({
            selectedPoi: undefined,
            selectedLane: undefined,
            selectedPhysicalStop: undefined,
            isToRecenter: recenter
        });
    }

    handlePhysicalStopSelection(physicalStop, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([physicalStop.clusterLatitude, physicalStop.clusterLongitude], () => this.getNearInfo());
        }

        this.setState({
            selectedPoi: undefined,
            selectedLane: undefined,
            selectedPhysicalStop: physicalStop,
            isToRecenter: recenter
        }, () => {
            this.getPhysicalStopTimes();
        });
    }

    handlePoiSelection(poi, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([poi.coordX, poi.coordY], () => this.getNearInfo());
        }

        this.setState({
            selectedLane: undefined,
            selectedPhysicalStop: undefined,
            selectedPoi: poi,
            isToRecenter: recenter
        });
    }

    handleLaneSelection(lane, recenter) {
        this.setState({
            selectedPoi: undefined,
            selectedPhysicalStop: undefined,
            selectedLane: lane,
            isToRecenter: recenter
        });
    }

    handleSearchClear() {
        this.setState({
            selectedPoi: undefined,
            selectedLane: undefined,
            selectedPhysicalStop: undefined
        });
    }

    handlePassingSelection(passing) {
        const { onSelectPassing } = this.props;
        onSelectPassing({ passing: passing, provider: passing.provider });
    }

    recenter() {
        const { recenter } = this.context;
        this.setState({
            isToRecenter: true
        }, () => {
            recenter(() => this.getNearInfo())
        });
    }

    determineIcon(physicalStop) {
        const { selectedPhysicalStop } = this.state;
        const isSelected = (undefined !== selectedPhysicalStop && selectedPhysicalStop.clusterId === physicalStop.clusterId && selectedPhysicalStop.clusterName === physicalStop.clusterName);
        const transportType = physicalStop.places.find(() => true).transportType;
        
        switch (transportType) {
            case 0: //Bus
                return isSelected ? selectedBusStopIcon : busStopIcon;

            case 1: //Train
                return isSelected ? selectedTrainStopIcon : trainStopIcon;

            case 2: //Boat
                return isSelected ? selectedBoatStopIcon : boatStopIcon;

            case 3: //Plane
                return isSelected ? selectedPlaneStopIcon : planeStopIcon;

            default: //Uncategorized
                return isSelected ? selectedStopIcon : stopIcon;
        };
    }

    determineIconPOI(poi) {
        const { selectedPoi } = this.state;
        const isSelected = (undefined !== selectedPoi && selectedPoi.id === poi.id);

        switch (poi.type) {
            case 4: //Ticket Office
                return isSelected ? selectedTicketOfficePoiIcon : ticketOfficePoiIcon;

            case 5: //Uncategorized
                return isSelected ? selectedUncategorizedPoiIcon : uncategorizedPoiIcon;

            case 6: // Bike Rental
                return isSelected ? selectedBikePoiIcon : bikePoiIcon;

            case 7: // Scooter rental
                return isSelected ? selectedScooterPoiIcon : scooterPoiIcon;

            case 8: // Boat rental
                return isSelected ? selectedBoatPoiIcon : boatPoiIcon;

            case 9: // Bike Parking
                return isSelected ? selectedBikeParkingPoiIcon : bikeParkingPoiIcon;

            case 10: // EV Charger
                return isSelected ? selectedEVChargerIcon : eVChargerIcon;

            default: //Uncategorized
                return isSelected ? selectedUncategorizedPoiIcon : uncategorizedPoiIcon;;
        };
    }

    formatDuration(duration) {
        if (duration <= 60) {
            return `${duration}min`;
        }

        let h = Math.floor(duration / 60);
        let m = duration % 60;
        m = m < 10 ? '0' + m : m;
        return `${h}h ${m}min`;
    }
    //--------------

    renderPassingInfos() {
        const { selectedPhysicalStop, isLoadingPassings } = this.state;

        //If not loading, but selected stop or selected stop passings are undefined, return nothing
        if (!isLoadingPassings && (undefined === selectedPhysicalStop || undefined === selectedPhysicalStop.passings)) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="passing-info-panel-header d-flex justify-content-between">
                    <div className="passing-info-panel-header-provider-info">
                        <div className="d-flex-inline text-truncate">
                            <b>{selectedPhysicalStop.clusterName}</b>
                        </div>
                    </div>
                    <div className="icon-refresh2 refresh-button" onClick={() => this.getPhysicalStopTimes()} />

                </div>
                <ListGroup className="passings-list">
                    {this.renderPassingsList()}
                </ListGroup>
            </div>
        );
    }

    renderPassingsList() {
        const { selectedPhysicalStop, isLoadingPassings } = this.state;

        if (isLoadingPassings) {
            return (
                <ListGroupItem key="stop-passing-spinner" className="text-align-center">
                    <Spinner animation="border" role="status" />
                </ListGroupItem>
            );
        }

        if (0 !== selectedPhysicalStop.passings.length) {
            return (
                selectedPhysicalStop.passings.map((passing, index) =>
                    <ListGroupItem key={`passing-${index}`} onClick={() => this.handlePassingSelection(passing)}>
                        <Row>
                            <Col xs={2} sm={2}>
                                {this.renderProviderLogo(passing.provider, "20px")}
                            </Col>
                            <Col xs={1} sm={1}>
                                <b>{passing.lineCode}</b>
                            </Col>
                            <Col xs={6} sm={7} className="d-flex align-items-center passings-list-destination-info-col">
                                {passing.destination}
                            </Col>
                            <Col xs={3} sm={2} className={passing.isRT ? "rt-passing-info d-flex align-items-center white-space-no-wrap" : "d-flex align-items-center white-space-no-wrap"}>
                                {this.formatDuration(passing.duration)}
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            );
        } else {
            return (
                <ListGroupItem key={`passing-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noPassingsToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }
    }

    renderPoiInfo() {
        const { selectedPoi } = this.state;

        if (undefined === selectedPoi) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="poi-info-panel-header d-flex justify-content-between">

                    <div className="d-flex-inline text-wrap">
                        <b>{selectedPoi.name}</b>
                    </div>

                    <div className="icon-error close-button" onClick={() => this.handleSearchClear()} />
                </div>
                <div className="passings-list poi-description">
                    {selectedPoi.description}
                    {selectedPoi.workingHours.map((wh, index) =>
                        <p key={`wh-${index}`}>{wh}</p>
                    )}
                    {selectedPoi.contacts.map((contact, index) =>
                        <p key={`ct-${index}`}>{contact}</p>
                    )}
                </div>
            </div>
        );
    }

    renderLaneInfo() {
        const { selectedLane } = this.state;

        if (undefined === selectedLane) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="poi-info-panel-header d-flex justify-content-between">

                    <div className="d-flex-inline text-wrap">
                        <b>{selectedLane.name}</b> - {selectedLane.type === 0 ? strings.cycling : strings.onFoot}
                    </div>

                    <div className="icon-error close-button" onClick={() => this.handleSearchClear()} />
                </div>
                <div className="passings-list poi-description">
                    {selectedLane.description}
                </div>
            </div>
        );
    }

    //----------
    renderProviderLogo(provider, height = "auto") {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img className="margin-right-5" height={height} src={imgUrl} alt={`${provider}`} />
        );
    }

    render() {
        const { isToRecenter, selectedPhysicalStop, filteredPhysicalStops, filteredPois, selectedPoi, filteredLanes, selectedLane, filterOptions } = this.state;
        const { getMapCenter } = this.context;

        return (
            <div className="stop-passing-info">
                <div className="stop-passing-info-panel">
                    <div className="stop-passing-info-search-bar-panel">
                        <SearchBar
                            className="stop-passing-info-search-bar"
                            resultsClassName="next-departures-search-bar-results-list"
                            caller="nextdepartures" placeholder={strings.searchPlaceholder}
                            onSelect={(place, recenter) => this.handleSearchResultSelection(place, recenter)}
                            onSearchClear={() => this.handleSearchClear()}
                            isRequired={false} />
                        {undefined !== selectedPhysicalStop ? this.renderPassingInfos() : null}
                        {undefined !== selectedPoi ? this.renderPoiInfo() : null}
                        {undefined !== selectedLane ? this.renderLaneInfo() : null}
                    </div>
                    <RecenterButton
                        className="margin-left-5"
                        title={strings.recenterTooltip}
                        recenter={() => this.recenter()}
                    />
                    <FilterButton 
                        className="margin-left-5" 
                        title={strings.optionsButtonTooltip}
                        filterOptions = {filterOptions} 
                        callback={(a, b)=>this.filterCallback(a, b)}
                    />
                </div>

                <MapContainer className="map-container" center={getMapCenter()} zoom={16} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    <MapEvents setCenter={(center, radius) => this.setMapCenter(center, radius)} />
                    <SetViewOnClick coords={getMapCenter()} isToRecenter={isToRecenter} />

                    {filteredPois.map((poi, index) => (
                        <Marker
                            key={`poi-${poi.coordX}-${poi.coordY}-${index}`}
                            position={[poi.coordX, poi.coordY]}
                            icon={this.determineIconPOI(poi)}
                            eventHandlers={{
                                click: () => {
                                    this.handlePoiSelection(poi, false);
                                }
                            }}
                        >
                            <Tooltip>
                                <strong>{poi.name}</strong>
                            </Tooltip>
                        </Marker>
                    ))}


                    {filteredPhysicalStops.map((physicalStop) =>
                        <Marker
                            key={`stop-${physicalStop.clusterId}${physicalStop.clusterName}`}
                            position={[physicalStop.clusterLatitude, physicalStop.clusterLongitude]}
                            icon={this.determineIcon(physicalStop)}
                            eventHandlers={{
                                click: () => {
                                    this.handlePhysicalStopSelection(physicalStop, false)
                                }
                            }}>

                            <Tooltip>
                                <strong>{physicalStop.clusterName}</strong>
                            </Tooltip>
                        </Marker>
                    )}


                    {filteredLanes.map((lane) => {
                        const positions = [];
                        lane.segments.forEach(segment => {
                            segment.breakpoints.forEach(breakpoint => {
                                positions.push([breakpoint.latitude, breakpoint.longitude])
                            })
                        });

                        return (
                            <Polyline
                                key={`polyline-lane-${lane.name}`}
                                positions={positions}
                                pathOptions={{ color: selectedLane !== undefined && selectedLane.id === lane.id ? "#00d27f" : "#0d4752" }}
                                eventHandlers={{
                                    click: () => {
                                        this.handleLaneSelection(lane, false)
                                    }
                                }}
                            />
                        )
                    }
                    )}
                </MapContainer>
            </div>
        );
    }
}

function SetViewOnClick({ coords, isToRecenter }) {
    const map = useMap();
    if (isToRecenter) {
        map.setView(coords, map.getZoom());
    }
    return null;
}

function MapEvents(args) {
    useMapEvents({
        dragend: (e) => {
            const radius = e.target.getCenter().distanceTo(e.target.getBounds().getNorthWest());
            args.setCenter(e.target.getCenter(), radius);
        },
        zoomend: (e) => {
            const radius = e.target.getCenter().distanceTo(e.target.getBounds().getNorthWest());
            args.setCenter(e.target.getCenter(), radius);
        }
    });
    return null;
}
