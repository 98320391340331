import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { DetailedRouteMap } from './DetailedRouteMap';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class RouteDetailedView extends Component {
    static contextTypes = {
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.detailedRouteMapRef = createRef();

        this.state = {
            displayTripInfo: true,
            displayMap: true,
            isSubtripExpanded: []
        }
    }

    componentDidMount() {
        const { trip } = this.props;

        this.setState({
            isSubtripExpanded: trip.subTrips.map(_ => false)
        });

        /*
         * Set function to handle when window is resized and immediately call it so we check the current screen size, otherwise it is only
         * triggered on resize.
        */
        window.onresize = () => this.resize();
        this.resize();
    }

    componentWillUnmount() {
        window.onresize = () => { };
    }

    //-------

    resize() {
        if (window.innerWidth > 767) {
            this.setState({
                displayTripInfo: true,
                displayMap: true
            });
        } else {
            document.getElementById("route-detailed-view-show-trip-button").click();
        }
    }

    calculeCheapestFare(output, fareTicket) {
        const price = fareTicket.tickets
            .reduce((output, ticket) => output += ticket.price, 0);

        if (undefined === output || price < output.price) {
            return {
                price: price,
                fareTicket: fareTicket,
            };
        }

        return output;
    }

    getPassingStopName(passings, stopId) {
        if (!Array.isArray(passings)) {
            return "";
        }

        const passing = passings.find(passing => passing.stopId === stopId);
        if (undefined === passing) {
            return "";
        }

        return passing.name;
    }

    determineWalkingSubTripIcon(changePreferences) {
        switch (changePreferences) {
            case 1:
                return "icons/bicycle.png";

            default:
                return "icons/walking.png";
        }
    }

    determineLineCode(trip) {
        const subtrip = trip.subTrips.find(st => undefined !== st && null !== st.lineCode && undefined !== st.lineCode);
        if (undefined !== subtrip) {
            return subtrip.lineCode;
        }

        return "";
    }

    determineTransportType(providerName) {
        const { providers } = this.props;

        const transportType = providers.find(p => p.name === providerName).transportType;

        switch (transportType.toUpperCase()) {
            case "FLUVIAL":
                return "icons/boat.png";
            case "COMBOIOS":
                return "icons/train.png";
            case "AVIÃO":
                return "icons/plane.png";
            default:
                return "icons/bus.png";
        }
    }

    determineBorderColor(subtrip) {
        return (
            "rgb(255,255,255)" !== subtrip.formattedLineBackColor || "rgb(255,255,255)" !== subtrip.formattedLineBackColor
                ? subtrip.formattedLineBackColor
                : null
        );
    }

    displayTripInfo() {
        this.setState({
            displayTripInfo: true,
            displayMap: false
        });
    }

    displayMap() {
        this.setState({
            displayTripInfo: false,
            displayMap: true
        });
    }

    handleSubtripClick(subtrip) {
        this.detailedRouteMapRef.current.panToSpecificSubtrip(subtrip);
    }

    handleAccordionClick(subtrip, subtripIndex) {
        const { isSubtripExpanded } = this.state;
        if (!isSubtripExpanded[subtripIndex]) {
            isSubtripExpanded[subtripIndex] = true;
            this.setState({
                isSubtripExpanded: isSubtripExpanded
            }, () => this.handleSubtripClick(subtrip));
        } else {
            isSubtripExpanded[subtripIndex] = false;
            this.setState({
                isSubtripExpanded: isSubtripExpanded
            });
        }
    }

    //RENDER
    renderDisplay() {
        const { displayTripInfo, displayMap } = this.state;
        const { trip } = this.props;

        if (displayTripInfo && displayMap) {
            return (
                <Row>
                    <Col sm={5} className="route-detailed-view-container-passings-col">
                        {this.renderInfo(trip)}
                    </Col>
                    <Col sm={7} className="route-detailed-view-container-map-col">
                        <DetailedRouteMap ref={this.detailedRouteMapRef} trip={trip} />
                    </Col>
                </Row>
            );
        } else {
            if (displayTripInfo) {
                return (
                    <Row>
                        <Col sm={12} className="route-detailed-view-container-passings-col">
                            {this.renderInfo(trip)}
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row>
                        <Col sm={12} className="route-detailed-view-container-map-col">
                            {this.renderHeader(trip)}
                            <DetailedRouteMap ref={this.detailedRouteMapRef} trip={trip} />
                        </Col>
                    </Row>
                );
            }
        }
    }

    renderInfo(trip) {
        if (undefined === trip) {
            return null;
        }

        return (
            <div className="route-detailed-view-container-passings-col-trip-info">
                {this.renderHeader(trip)}
                {this.renderTripInfo(trip)}
            </div>
        );
    }

    renderHeader() {
        const { onReturn } = this.props;
        const { displayMap, displayTripInfo } = this.state;

        return (
            <div>
                <Button className="plan-route-detailed-view-back-button margin-bottom-10" variant="light" onClick={() => onReturn()}>
                    <div className={"icon-redo"} />
                </Button>

                <div className="route-detailed-view-small-screen-tabs">
                    <Button id="route-detailed-view-show-trip-button" className={`route-detailed-view-small-screen-tabs-link-button ${displayTripInfo ? "orange-text" : ""}`} variant="link" onClick={() => this.displayTripInfo()}>
                        {strings.showTrip}
                    </Button>
                    <span className="orange-text">|</span>
                    <Button id="route-detailed-view-show-map-button" className={`route-detailed-view-small-screen-tabs-link-button ${displayMap ? "orange-text" : ""}`} variant="link" onClick={() => this.displayMap()}>
                        {strings.showMap}
                    </Button>
                </div>
            </div>
        );
    }

    renderFareTicket(trip) {
        if (undefined === trip) {
            return null;
        }

        let cheapestFare = undefined;
        if (Array.isArray(trip.fareTickets)) {
            cheapestFare = trip.fareTickets
                .reduce(this.calculeCheapestFare, undefined);
        }

        if (undefined === cheapestFare) {
            return null;
        }

        const tripPassings = trip.subTrips
            .reduce((output, subTrip) => output.concat(subTrip.passings), []);

        return (
            <div className="fare-ticket">
                {cheapestFare.fareTicket.tickets.map((ticket, idx) =>
                    <div key={`fare-ticket-${idx}`} className="ticket">
                        <span className="stops">{strings.from} <b>{this.getPassingStopName(tripPassings, ticket.from)}</b> {strings.to} <b>{this.getPassingStopName(tripPassings, ticket.to)}</b></span>
                        <span className="name">{ticket.name}</span>
                        <span className="price">{ticket.price.toFixed(2)} {ticket.currency.symbol}</span>
                    </div>
                )}
            </div>
        );
    }

    renderTripInfo(trip) {
        if (0 === trip.subTrips.length) {
            return null;
        }

        const { changePreferences } = this.props;

        if (1 === trip.subTrips.length) {
            return (
                <div className="plan-route-detailed-view-trip-info">
                    {this.renderSingleSubtrip(changePreferences, trip, trip.subTrips[0], 0)}
                    {this.renderFareTicket(trip)}
                </div>
            );
        }

        const lastSubTripIndex = trip.subTrips
            .length - 1;

        return (
            <div className="plan-route-detailed-view-trip-info">
                {
                    trip.subTrips.map((subtrip, index) => {
                        if (0 === index) {
                            return this.renderFirstSubtrip(changePreferences, trip, subtrip, index);
                        }

                        if (lastSubTripIndex === index) {
                            return this.renderLastSubtrip(changePreferences, trip, subtrip, index);
                        }

                        return this.renderIntermediateSubtrip(changePreferences, subtrip, index);
                    })
                }

                <div className="plan-route-detailed-view-trip-duration-info">
                    <span>{strings.duration}: {trip.totalTripDuration}</span>
                </div>

                {this.renderFareTicket(trip)}
            </div>
        );
    }

    renderSingleSubtrip(changePreferences, trip, subtrip, subtripIndex) {
        return (
            <div className="cursor-pointer" key={`single-trip-${trip.startPlace.name}-${trip.endPlace.name}`}>
                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.departureTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prOrigin.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.startPlace.name}</b>

                {this.renderSubtripInfo(changePreferences, subtrip, subtripIndex)}

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.endPlace.name}</b>
            </div>
        );
    }

    renderFirstSubtrip(changePreferences, trip, subtrip, subtripIndex) {
        return (
            <div className="cursor-pointer" key={`first-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`}>
                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.departureTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prOrigin.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.startPlace.name}</b>

                {this.renderSubtripInfo(changePreferences, subtrip, subtripIndex)}

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
            </div>
        );
    }

    renderIntermediateSubtrip(changePreferences, subtrip, subtripIndex) {
        return (
            <div className="cursor-pointer" key={`intermediate-subtrip-${subtripIndex}`}>
                {this.renderSubtripInfo(changePreferences, subtrip, subtripIndex)}

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <span>{subtrip.passings[subtrip.passings.length - 1].name}</span>
            </div>
        );
    }

    renderLastSubtrip(changePreferences, trip, subtrip, subtripIndex) {
        return (
            <div className="cursor-pointer" key={`last-subtrip-${trip.startPlace.name}-${trip.endPlace.name}-${subtrip.order}`}>
                {this.renderSubtripInfo(changePreferences, subtrip, subtripIndex)}

                <span className="plan-route-detailed-view-subtrip-hour-span">{subtrip.arrivalTimeToShow}</span>
                <img className="margin-left-negative3" src="icons/prDestination.png" alt="" width="25px" height="25px" />
                &nbsp;
                <b>{trip.endPlace.name}</b>
            </div>
        );
    }

    renderSubtripInfo(changePreferences, subtrip, subtripIndex) {
        const { getLogo } = this.context;
        const relevantSubtripPassings = JSON.parse(JSON.stringify(subtrip.passings)); //Clone without reference
        relevantSubtripPassings.shift(); //Remove first element
        relevantSubtripPassings.pop(); //Remove last element

        if (subtrip.isWalking) {
            return (
                <div className="d-flex align-items-center padding-top-10 padding-bottom-10" onClick={() => this.handleSubtripClick(subtrip)}>
                    <div className="width-50px text-align-center">
                        <img src={this.determineWalkingSubTripIcon(changePreferences)} alt="" height="50px" />
                    </div>
                    <div className="vertical-dotted-line" />
                    <span className="padding-left-10">{strings.walkAround} {subtrip.walkingTime}, {subtrip.distance}m</span>
                </div>
            );
        }

        if (relevantSubtripPassings.length > 0) {
            return (
                <Accordion className="plan-route-detailed-view-subtrip-custom-accordion">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => this.handleAccordionClick(subtrip, subtripIndex)}>
                            <div className="plan-route-detailed-view-subtrip-accordion-header">
                                <div className="width-50px text-align-center">
                                    <img src={this.determineTransportType(subtrip.provider)} alt="" height="50px" />
                                </div>
                                <div className="vertical-filled-line" /*style={{ borderColor: this.determineBorderColor(subtrip) }}*/ />
                                &nbsp;
                                <div className="padding-left-10">
                                    <img src={getLogo(subtrip.provider)} alt={subtrip.provider} height="20px" />
                                    &nbsp;
                                    <span><b className="trip-line-code"/* style={{ background: subtrip.formattedLineBackColor, color: subtrip.formattedLineForeColor }}*/>{subtrip.lineCode}</b> {subtrip.passings[0].destination}</span>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                relevantSubtripPassings.map((p, index) =>
                                    <div key={`subtrip-${index}`} className="plan-route-detailed-view-subtrip-accordion-stop-info">
                                        <div className="width-50px text-align-center">
                                            {p.formattedDuration}
                                        </div>
                                        <div className="vertical-filled-line" /*style={{ borderColor: this.determineBorderColor(subtrip) }}*/ />
                                        &nbsp;
                                        <div className="padding-left-10">
                                            <span>
                                                {p.name}
                                                <br />
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            );
        }

        return (
            <div>
                <div className="d-flex align-items-center padding-top-10 padding-bottom-10" onClick={() => this.handleSubtripClick(subtrip)}>
                    <div className="width-50px text-align-center">
                        <img src={this.determineTransportType(subtrip.provider)} alt="" height="50px" />
                    </div>
                    <div className="vertical-filled-line" /*style={{ borderColor: this.determineBorderColor(subtrip) }}*/ />
                    &nbsp;
                    <div className="padding-left-10">
                        <img src={getLogo(subtrip.provider)} alt={subtrip.provider} height="20px" />
                        &nbsp;
                        <span><b className="trip-line-code" /*style={{ background: subtrip.formattedLineBackColor, color: subtrip.formattedLineForeColor }}*/>{subtrip.lineCode}</b> {subtrip.passings[0].destination}</span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Container fluid className="route-detailed-view-container">
                {this.renderDisplay()}
            </Container>
        );
    }
}