import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Spinner, Overlay, Popover } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { ProviderCommand } from '../commands/ProviderCommand';
import { ScheduleCommand } from '../commands/ScheduleCommand';
import { TripCommand } from '../commands/TripCommand';

import './Schedules.css';
export class Schedules extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "schedules";

        this.state = {
            commands: {
                provider: new ProviderCommand(),
                schedule: new ScheduleCommand(),
                trip: new TripCommand()
            },
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            providersTransportTypes: [],
            selectedTransportTypes: [],
            providers: [],
            selectedProvider: undefined,
            lines: [],
            selectedLine: undefined,
            selectedDirection: undefined,
            dayTypes: [],
            selectedDaytype: undefined,
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
            isLoadingInfo: false,
            displayNoInfo: false,
            displayExceptionsPopover: false,

        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getProviders();
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;

        setState(this.stateKey, this.state);
    }

    //DATA
    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        if (1 === result.length) {
            this.getProviderLines(result[0]);
        }
        this.getProvidersTransportTypes(result);

        this.setState({
            providers: result
        });
    }

    getProvidersTransportTypes(providers) {
        var transportTypes = providers.map(provider => provider.transportType ? provider.transportType : "");
        transportTypes = [...new Set(transportTypes)];

        this.setState({
            providersTransportTypes: [...transportTypes],
            selectedTransportTypes: [...transportTypes]
        })
    }

    getProviderLines(provider) {
        const { commands } = this.state;
        const providerName = undefined !== provider.name ? provider.name : "";
        commands.provider.getProviderLines(providerName, (r) => this.providerLinesSuccessCallback(r))
    }

    providerLinesSuccessCallback(result) {
        this.setState({
            lines: result,
            displayLineDropdown: true,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
        });
    }

    getDaytypes() {
        const { selectedLine, commands } = this.state;
        commands.schedule.getScheduleByLineGroupByDayType(selectedLine.id, (r) => this.dayTypesSuccessCallback(r));
    }

    dayTypesSuccessCallback(result) {
        if (0 === result.length) {
            this.setState({
                displayNoInfo: true
            });
        } else if (1 === result.length) {
            this.setState({
                dayTypes: result,
                selectedDaytype: result[0],
                displayDaytypeDropdown: true,
                isLoadingInfo: true
            }, () => {
                this.getTrips();
            });
        } else {
            this.setState({
                dayTypes: result,
                displayDaytypeDropdown: true
            });
        }
    }

    getTrips() {
        const { commands, selectedLine, selectedDirection, selectedDaytype } = this.state;
        commands.trip.getTripsByLineAndDayType(
            selectedLine.id, selectedDirection, selectedDaytype.schedules.join(), (r) => this.getTripsSuccessCallback(r));
    }

    getTripsSuccessCallback(result) {
        if (result.trips.length > 0) {
            this.setState({
                scheduleInfo: result,
                isLoadingInfo: false
            });
        } else {
            this.setState({
                displayNoInfo: true,
                isLoadingInfo: false
            });
        }
    }

    //---------

    //FUNCTIONS
    setProvider(provider) {
        this.setState({
            selectedProvider: provider,
            selectedLine: undefined,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
            displayNoInfo: false
        }, () => {
            this.getProviderLines(provider);
        });
    }

    manageFilters(transportType) {
        const { providersTransportTypes, selectedTransportTypes } = this.state;

        if (selectedTransportTypes.includes(transportType) && selectedTransportTypes.length <= 1) {
            this.setState({
                selectedTransportTypes: [...providersTransportTypes]
            });
            return;
        } else if (selectedTransportTypes.includes(transportType)) {
            const index = selectedTransportTypes.indexOf(transportType);
            if (index > -1) {
                selectedTransportTypes.splice(index, 1);
            }
        } else {
            selectedTransportTypes.push(transportType);
        }

        this.setState({
            selectedTransportTypes: selectedTransportTypes
        });
    }

    setLine(line) {
        if (line === undefined) {
            return;
        }

        this.setState({
            selectedLine: line,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDirectionDropdown: true,
            displayDaytypeDropdown: false,
            displayNoInfo: false
        });
    }

    setDirection(direction) {
        if (direction === undefined) {
            return;
        }

        this.setState({
            selectedDirection: direction,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDaytypeDropdown: false,
            displayNoInfo: false
        }, () => {
            this.getDaytypes();
        });
    }

    determineDirectionString(direction) {
        switch (direction) {
            case "G":
                return strings.directionGo;
            case "R":
                return strings.directionReturn;
            case "C":
                return strings.directionCircular;
            default:
                return "";
        }
    }

    setDaytype(daytype) {
        this.setState({
            selectedDaytype: daytype,
            isLoadingInfo: true,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayNoInfo: false
        }, () => {
            this.getTrips();
        });
    }

    setDisplayExceptionsPopover(displayExceptionsPopover) {
        this.setState({
            displayExceptionsPopover: displayExceptionsPopover
        });
    }

    //---------------

    //RENDER

    renderDropdowns() {
        return (
            <div>
                {this.renderTransportTypeFilters()}
                {this.renderProviderDropdown()}
                {this.renderLinesDropdown()}
                {this.renderDirectionsDropdown()}
                {this.renderDaytypesDropdown()}
            </div>
        );
    }

    renderTransportTypeFilters() {
        const { providersTransportTypes } = this.state;
        if (1 >= providersTransportTypes.length) {
            return null;
        }

        return (
            <div className="schedules-transport-type-filter">
                <div className="schedules-transport-type-filter-description">{strings.transportTypeFilter}</div>
                {
                    providersTransportTypes.map(transportType =>
                        <div key={transportType} onClick={() => this.manageFilters(transportType)}>
                            <div>
                                {this.renderTransportTypeLogo(transportType)}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    renderTransportTypeLogo(transportType) {
        const { selectedTransportTypes } = this.state;
        const isFiltered = selectedTransportTypes.includes(transportType);
        var image = ""
        var className = "schedules-transport-type "
        switch (transportType) {
            case "Autocarros":
                image = "icons/bus.png"
                className += isFiltered ? "schedules-transport-type-selected" : "schedules-bus-filtered"
                break;
            case "Fluvial":
                image = "icons/boat.png"
                className += isFiltered ? "schedules-transport-type-selected" : "schedules-boat-filtered"
                break;
            case "Avião":
                image = "icons/plane.png"
                className += isFiltered ? "schedules-transport-type-selected" : "schedules-plane-filtered"
                break;
            case "Comboios":
                image = "icons/train.png"
                className += isFiltered ? "schedules-transport-type-selected" : "schedules-train-filtered"
                break;
            default:
                return;
        }

        return (
            <div>
                <img className={className} height="70px" src={image} alt={transportType}/>
            </div>
        );
    }

    renderProviderDropdown() {
        const { providers, selectedProvider, selectedTransportTypes } = this.state;

        if (1 >= providers.length) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button
                    variant="secondary"
                    className="dropdown-toggle schedules-provider-button"
                    id="providerDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    {selectedProvider !== undefined ? (
                        <div className="provider-info">
                            <span className="provider-logo">
                                {this.renderProviderLogo(selectedProvider.name)}
                            </span>
                            <div className="provider-details">
                                <span className="schedules-provider-dropdown-list-option">
                                    {Array.isArray(selectedProvider.regions) && selectedProvider.regions.length > 0
                                        ? selectedProvider.regions.length > 1
                                            ? selectedProvider.regions.join(' | ')
                                            : selectedProvider.regions[0]
                                        : selectedProvider.name}
                                </span>
                                <span className="dropdown-list-option-description">
                                    {selectedProvider.description}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="provider-info">
                            <span className="schedules-provider-dropdown-list-option">
                                {strings.provider}
                            </span>
                        </div>
                    )}
                </Button>

                <ul className="dropdown-menu schedules-provider-dropdown-list" aria-labelledby="providerDropdown">
                    {providers
                        .filter((provider) => selectedTransportTypes.includes(provider.transportType))
                        .map((provider) => (
                            <li key={provider.name} onClick={() => this.setProvider(provider)}>
                                <div className="dropdown-item provider-info">
                                    {this.renderProviderLogo(provider.name)}
                                    <span className="schedules-provider-dropdown-list-option">
                                        {Array.isArray(provider.regions) && provider.regions.length > 0
                                            ? provider.regions.length > 1
                                                ? provider.regions.join(' | ')
                                                : provider.regions[0]
                                            : provider.name}
                                    </span>
                                    <span className="schedules-provider-dropdown-list-option dropdown-list-option-description">
                                        {provider.description}
                                    </span>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        );
    }

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (imgUrl) {
            return (
                <div className="provider-logo-container">
                    <img className="provider-logo" src={imgUrl} alt={`${provider}`} />
                    <span className="provider-logo-text">{provider.name}</span>
                </div>
            );
        } else {
            return (
                <div className="provider-logo-container">
                    <div className="provider-logo-placeholder"></div>
                    <span className="provider-logo-text">{provider.name}</span>
                </div>
            );
        }
    }

    renderLinesDropdown() {
        const { lines, selectedLine, displayLineDropdown } = this.state;

        if (!displayLineDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-line-button" id="lineDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedLine !== undefined ?
                            <span className="overflow-x-clip">
                                <b>{selectedLine.code}</b>
                                &nbsp;
                                {selectedLine.goName}
                            </span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.line}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-line-dropdown-list" aria-labelledby="lineDropdown">
                    {
                        lines.map(line =>
                            <li key={line.id} onClick={() => this.setLine(line)}>
                                <span className="dropdown-item">
                                    <b>{line.code}</b>
                                    &nbsp;
                                    {line.goName}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderDirectionsDropdown() {
        const { selectedLine, selectedDirection, displayDirectionDropdown } = this.state;

        if (!displayDirectionDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-direction-button" id="directionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDirection !== undefined ?
                            <span className="overflow-x-clip">{this.determineDirectionString(selectedDirection)}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.direction}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-direction-dropdown-list" aria-labelledby="directionDropdown">
                    {
                        undefined !== selectedLine.directions ?
                            selectedLine.directions.map(direction =>
                                <li key={direction} onClick={() => this.setDirection(direction)}>
                                    <span className="dropdown-item">
                                        {this.determineDirectionString(direction)}
                                    </span>
                                </li>
                            )
                            :
                            null
                    }
                </ul>
            </div>
        );
    }

    renderDaytypesDropdown() {
        const { displayDaytypeDropdown, dayTypes, selectedDaytype } = this.state;

        if (!displayDaytypeDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-daytype-button" id="daytypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDaytype !== undefined ?
                            <span className="overflow-x-clip">{selectedDaytype.name}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.daytype}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-daytype-dropdown-list" aria-labelledby="daytypeDropdown">
                    {
                        dayTypes.map(daytype =>
                            <li key={daytype.name} onClick={() => this.setDaytype(daytype)}>
                                <span className="dropdown-item">
                                    {daytype.name}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderScheduleInfo() {
        const { isLoadingInfo, scheduleInfo, displayNoInfo } = this.state;

        if (isLoadingInfo) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" style={{ color: "#0d4752" }} />
                </div>
            );
        }

        if (displayNoInfo) {
            return (
                <div className="schedules-no-info-to-show">
                    <h5>{strings.noInfoToShow}</h5>
                </div>
            );
        }

        const hasExceptions = Array.isArray(scheduleInfo.exceptions) && 0 < scheduleInfo.exceptions.length;

        return (
            <div className="schedules-schedule-info">
                <div className="schedules-schedule-trip-info">
                    {this.renderStopsList(scheduleInfo, hasExceptions)}
                    {this.renderTripList(scheduleInfo, hasExceptions)}
                </div>
            </div>
        );
    }

    renderStopsList(scheduleInfo, hasExceptions) {
        let exceptionsList = null
        if (hasExceptions) {
            const { displayExceptionsPopover } = this.state;
            const overlayRef = React.createRef();

            exceptionsList = (
                <div ref={overlayRef}
                    className="schedules-schedule-notes-info"
                    onClick={() => this.setDisplayExceptionsPopover(!displayExceptionsPopover)}
                    onMouseEnter={() => this.setDisplayExceptionsPopover(true)}
                    onMouseLeave={() => this.setDisplayExceptionsPopover(false)}>
                    <span className="icon icon-info">{strings.notes}</span>
                    {this.renderExceptionList(scheduleInfo, displayExceptionsPopover, overlayRef)}
                </div>
            );
        }

        return (
            <div className="schedules-schedule-stop-info">
                {exceptionsList}
                {
                    scheduleInfo.stops.map((stop, index) =>
                        <div key={`stop-${index}`}>
                            {stop.name}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripList(scheduleInfo, hasExceptions) {
        return (
            <div className="schedules-schedule-passing-info">
                {
                    scheduleInfo.trips.map((trip, index) =>
                        <div key={`trip-${index}`} className="schedules-schedule-passing-info-list">
                            {hasExceptions ? this.renderTripExceptions(trip) : null}
                            {this.renderTripPassings(trip, scheduleInfo.stops)}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripExceptions(trip) {
        const exceptions = trip
            .filter(passing => Array.isArray(passing.exceptions) && 0 < passing.exceptions.length)
            .reduce((output, passing) => {
                passing.exceptions.forEach(exception => {
                    if (!output.includes(exception)) {
                        output.push(exception);
                    }
                });

                return output;
            }, []).sort((a, b) => a.localeCompare(b)).join(", ");


        return (
            <div className="white-space-no-wrap">
                {"" === exceptions ? <span>&nbsp;</span> : exceptions}
            </div>
        );
    }

    renderTripPassings(trip, stops) {
        return stops.map(stop => {
            const passing = trip.find(passing => passing.stopId === stop.id && passing.stopOrder === stop.order);
            if (undefined === passing) {
                return (
                    <div key={`undefined-time-${trip.order}-${stop.id}-${stop.order}`} className="schedules-trip-passing-list-value">
                        --:--
                    </div>
                );
            }

            return (
                <div key={`time-${trip.order}-${stop.id}-${stop.order}`} className="schedules-trip-passing-list-value">
                    {passing.time}
                </div>
            );
        });
    }

    renderExceptionList(scheduleInfo, displayExceptionsPopover, overlayRef) {
        return (
            <Overlay target={overlayRef} show={displayExceptionsPopover} placement="bottom-start">
                <Popover id="popover-exceptions" placement="bottom">
                    <Popover.Body>
                        <div className="schedules-schedule-exception-info-list">
                            {
                                scheduleInfo.exceptions.map(exception =>
                                    <div key={`schedule-${exception}`}>
                                        {exception}
                                    </div>
                                )
                            }
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        );
    }

    render() {
        return (
            <div className="schedules-panel">
                {this.renderDropdowns()}
                {this.renderScheduleInfo()}
            </div>
        );
    }
}
