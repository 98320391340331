import React, { Component } from 'react';

export class RecenterButton extends Component {
    render() {
        const { className, recenter, title } = this.props;

        return (
            <div className={className}>
                <button className="recenter-button" title={title} onClick={() => recenter()}>
                    <div className="icon-recenter" />
                </button>
            </div>
        );
    }
}