import { API, HTTPMethod, ContentType } from './APICentral';
import { APIEncoder } from './APIEncoder';


const poiApiRoutes = {
    defaultRoute: "pointofinterest/"
};

export class PoiAPI {
    static getPois() {
        const url = `${poiApiRoutes.defaultRoute}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }

    static getNearPois(latitude, longitude, radius) {
        const url = `${poiApiRoutes.defaultRoute}${latitude}/${longitude}/${radius}`;

        return API.authenticatedRequest({
            url: url,
            method: HTTPMethod.GET,
            contentType: ContentType.JSON,
            key: APIEncoder.getApiKey()
        });
    }
}