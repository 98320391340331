import React, { Component } from 'react';
import { MenuItem } from './MenuItem';
import './NavMenu.css';
import { PropTypes } from 'prop-types';
import { strings } from "../../resources/strings.js";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    static contextTypes = {
        updateLanguage: PropTypes.func
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    changeLanguage(languageKey) {
        const { updateLanguage } = this.context;
        updateLanguage(languageKey);
    }

    render() {
        const { isMenuOpen } = this.props;

        return (
            <nav className={isMenuOpen ? "icon-nav-open" : "icon-nav"}>
                <div className="menu-background-header" />
                <div className="menu-background" />
                <div className="icon-nav-item">
                    <div className="menu-toggle" onClick={this.props.toggleMenuHandler}>
                        <div className="icon-menu-1 padding-top-15" />
                        <div>
                            <img className="logo-img" width={isMenuOpen ? "150px" : "0px"} src="icons/logo-neso.png" alt="Neso" />
                        </div>
                    </div>

                    <MenuItem key="menu-next-departures" label={strings.nextDepartures} icon="icon-zones" to="/nextdepartures" />
                    <MenuItem key="menu-plan-route" label={strings.planRoute} icon="icon-routeplan" to="/plan-route" />
                    <MenuItem key="menu-schedules" label={strings.schedules} icon="icon-clock" to="/schedules" />
                    <MenuItem key="menu-about" label={strings.about} icon="icon-info" to="/about" />

                    <div className={"icon-menu-1 menu-flag-icons language-panel"}>
                        <img width={isMenuOpen ? "25px" : "0px"} src="icons/pt-flag.png" alt="pt" onClick={() => this.changeLanguage('pt')} />
                        <img className={"icon-uk-flag"} width={isMenuOpen ? "25px" : "0px"} src="icons/uk-flag.png" alt="en" onClick={() => this.changeLanguage('en')} />
                        <img className={"icon-fr-flag"} width={isMenuOpen ? "25px" : "0px"} src="icons/fr-flag.png" alt="en" onClick={() => this.changeLanguage('fr')} />
                        <img className={"icon-es-flag"} width={isMenuOpen ? "25px" : "0px"} src="icons/es-flag.png" alt="en" onClick={() => this.changeLanguage('es')} />
                    </div>
                </div>
            </nav>
        );
    }
}
