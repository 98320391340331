import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { StopPassingInfo } from './StopPassingInfo';
import { LineInfo } from './LineInfo';
import { MoreSchedules } from './MoreSchedules';

export class NextDepartures extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "nextDepartures";
        this.stopPassingInfoRef = createRef();

        this.state = {
            render: {
                component: null,
                key: "stopPassingInfo",
                args: {}
            },
            available: {
                stopPassingInfo: {
                    type: StopPassingInfo,
                    callbacks: {
                        ref: this.stopPassingInfoRef,
                        onSelectPassing: (args) => this.onChangeView("lineInfo", args)
                    }
                },
                lineInfo: {
                    type: LineInfo,
                    callbacks: {
                        onReturn: (args) => this.onChangeView("stopPassingInfo", args),
                        onMoreSchedsClick: (args) => this.onChangeView("moreSchedules", args)
                    }
                },
                moreSchedules: {
                    type: MoreSchedules,
                    callbacks: {
                        onReturn: (args) => this.onChangeView("lineInfo", args),
                    }
                }
            }
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            const { render, available } = this.state;
            render.component = available.stopPassingInfo;
            this.setState({
                render: render
            });
        } else {
            const { render, available } = this.state;

            render.component = available[state.key];
            render.key = state.key;
            render.args = state.args;

            this.setState({
                render: render
            });
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        const { render } = this.state;

        setState(this.stateKey, render);
    }

    onChangeView(view, args) {
        const { render, available } = this.state;
        render.component = available[view];
        render.key = view;
        render.args = args;

        this.setState({
            render: render
        });
    }

    getNearStops() {
        /*
         * This is only called in App.js.
         * Used to make sure when user accepts prompt for location we get the nearstops with the new coords.
         */

        if (null !== this.stopPassingInfoRef.current) {
            this.stopPassingInfoRef.current.getNearStops();
        }
    }

    render() {
        const { render } = this.state;

        if (null === render.component || undefined === render.component) {
            return null;
        }

        const view = React.createElement(
            render.component.type,
            Object.assign(render.component.callbacks, render.args)
        );

        return (
            <div className="next-departures-view">
                {view}
            </div>
        );
    }
}

