import React, { Component } from 'react';
import { FilterMenu } from './FilterMenu';

export class FilterButton extends Component {
    constructor(props){
      super(props);

      this.state = {
        isOpen: false
      }
    }

    onChangeOpen(){
      const { isOpen } = this.state;

      let newOpen = !isOpen;

      this.setState({
        isOpen: newOpen
      });
    }

    render() {
        const { className, callback, filterOptions, title } = this.props;
        const { isOpen } = this.state;

        return (
            <div className={className}>
                <button className="filter-button" title={title} onClick={() => {
                  this.onChangeOpen();
                }}>
                    <div className="icon-filter" />
                </button>
                {isOpen? 
                  <FilterMenu 
                    filterOptions= {filterOptions}
                    callback={callback}
                  /> 
                : null}
            </div>
        );
    }
}