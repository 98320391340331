import React, { Component } from 'react';
import { strings } from '../../resources/strings';

export class About extends Component {
    //RENDER

    render() {
        return ( 
            <div className="about-view">
                <h3>{strings.title_who}</h3>
                <p>{strings.text_who}</p>

                <h3>{strings.title_what}</h3>
                <p>{strings.text_what}</p>

                <h3>{strings.title_how}</h3>
                <p>{strings.text_how}</p>

                <h3>{strings.title_plan}</h3>
                <p>{strings.text_plan}</p>

                <h3>{strings.title_info}</h3>
                <p>{strings.text_info}</p>
            </div>
        );
    }
}
